.js-customScroll {
	max-height: 300px; // частный случай
	overflow: hidden;

	.os-host-resize-disabled.os-host-scrollbar-horizontal-hidden > .os-scrollbar-vertical {
		// os-scrollbar
		// os-scrollbar-vertical
		width: 8px;
		height: 100%;
	}

	.os-scrollbar-track {
		// подложка
		border: 1px solid $color-white !important;
		// +вариант без бордера с фоном
	}

	.os-scrollbar-handle {
		// таскалка: варировать цвет
		background-color: $color-white;

		cursor: grab;
	}
}

.js-menu-scrollbar,
.js-mobile-menu-panel {
	overflow: hidden;

	.os-scrollbar-vertical {
		position: absolute;
		top: 138px !important;
		right: 5px;
		height: calc(90% - 138px);
	}

	.os-scrollbar-handle {
		// таскалка: варировать цвет
		background-color: $color-white !important;
		left: -2px;
		width: 4px;
		border-radius: 10px;

		cursor: grab;
	}
}

.js-mobile-menu-panel {
	.os-scrollbar-vertical {
		right: 0;
		top: 55px !important;
		height: calc(96% - 55px);
	}
}

@include breakpoint(md) {
	.js-menu-scrollbar {
		.os-scrollbar-vertical {
			top: 85px !important;
			height: calc(90% - 85px);
		}
	}
}
