$mobile-menu_vertical-accordeon--font-size: 15px;
$mobile-menu_vertical-accordeon--main-font-size: 23px;
$mobile-menu_vertical-accordeon--button-width: 32px;               // The width of the button area
$mobile-menu_vertical-accordeon--button-height: 11px;                   // The height of the button area
$mobile-menu_vertical-accordeon--bar-thickness: 1px;                    // The thickness of the button bars
$mobile-menu_vertical-accordeon--bar-space: 5px;                 // The spacing between button bars
$mobile-menu_vertical-accordeon--transistion-duration: 0.3s;     // The transition duration
$mobile-menu_vertical-accordeon--bar-width: 32px;

$mobile-menu_vertical-accordeon--panel-bg: $color-radial;
$mobile-menu_vertical-accordeon--link-color: $base-text-color;
$mobile-menu_vertical-accordeon--link-color-hover: $color-deep-sky-blue;
$mobile-menu_vertical-accordeon--link-color-active: $color-deep-sky-blue;
$mobile-menu_vertical-accordeon--controller-color: $base-text-color;
$mobile-menu_vertical-accordeon--controller-color-hover: $color-deep-sky-blue;
$mobile-menu_vertical-accordeon--controller-color-active: $color-deep-sky-blue;
$mobile-menu_vertical-accordeon--button-bg: transparent;
$mobile-menu_vertical-accordeon--button-bg-hover: transparent;
$mobile-menu_vertical-accordeon--button-bg-active: transparent;
$mobile-menu_vertical-accordeon--button-color: $color-white;
$mobile-menu_vertical-accordeon--button-color-hover: $color-white;
$mobile-menu_vertical-accordeon--button-color-active: $color-deep-sky-blue;
$mobile-menu_vertical-accordeon--link-lvl1-bg: transparent;
$mobile-menu_vertical-accordeon--link-lvl2-bg: #eee;
$mobile-menu_vertical-accordeon--link-lvl3-bg: #fff;

@if ($contrast) {
	$mobile-menu_vertical-accordeon--panel-bg: $color-radial;
	$mobile-menu_vertical-accordeon--link-color: $base-text-color;
	$mobile-menu_vertical-accordeon--link-color-hover: $color-deep-sky-blue;
	$mobile-menu_vertical-accordeon--link-color-active: $color-deep-sky-blue;
	$mobile-menu_vertical-accordeon--controller-color: $base-text-color;
	$mobile-menu_vertical-accordeon--controller-color-hover: $color-deep-sky-blue;
	$mobile-menu_vertical-accordeon--controller-color-active: $color-deep-sky-blue;
	$mobile-menu_vertical-accordeon--button-bg: transparent;
	$mobile-menu_vertical-accordeon--button-bg-hover: transparent;
	$mobile-menu_vertical-accordeon--button-bg-active: transparent;
	$mobile-menu_vertical-accordeon--button-color: $color-white;
	$mobile-menu_vertical-accordeon--button-color-hover: $color-white;
	$mobile-menu_vertical-accordeon--button-color-active: $color-deep-sky-blue;
	$mobile-menu_vertical-accordeon--link-lvl1-bg: transparent;
	$mobile-menu_vertical-accordeon--link-lvl2-bg: #eee;
	$mobile-menu_vertical-accordeon--link-lvl3-bg: #fff;
}

.b-mobile-menu.b-mobile-menu--vertical-accordion {
	@include rem(width, $mobile-menu_vertical-accordeon--button-width);
	@include rem(height, $mobile-menu_vertical-accordeon--button-height);

	.b-mobile-menu__burger {
		display: block;
		z-index: 10;
		position: relative;
		background-color: $mobile-menu_vertical-accordeon--button-bg;
		@include rem(width, $mobile-menu_vertical-accordeon--button-width);
		@include rem(height, $mobile-menu_vertical-accordeon--button-height);

		&:hover {
			background-color: $mobile-menu_vertical-accordeon--button-bg-hover;

			.b-mobile-menu__burger-ingredients-middle,
			.b-mobile-menu__burger-ingredients:before,
			.b-mobile-menu__burger-ingredients:after {
				background-color: $mobile-menu_vertical-accordeon--button-color-hover;
			}
		}

		&:active {
			background-color: $mobile-menu_vertical-accordeon--button-bg-active;

			.b-mobile-menu__burger-ingredients-middle,
			.b-mobile-menu__burger-ingredients:before,
			.b-mobile-menu__burger-ingredients:after {
				background-color: $mobile-menu_vertical-accordeon--button-color-active;
			}
		}

		.b-mobile-menu__burger-ingredients {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: $mobile-menu_vertical-accordeon--bar-thickness;
			width: $mobile-menu_vertical-accordeon--bar-width;
			margin: auto;

			&:before,
			&:after {
				content: '';
				position: absolute;
				display: block;
				left: 0;
				width: 100%;
				height: $mobile-menu_vertical-accordeon--bar-thickness;
				background-color: $mobile-menu_vertical-accordeon--button-color;
				transition-duration: $mobile-menu_vertical-accordeon--transistion-duration, $mobile-menu_vertical-accordeon--transistion-duration;
				transition-delay: $mobile-menu_vertical-accordeon--transistion-duration, 0s;
				will-change: transform;
				transition: background-color 0.2s ease;
			}

			&:before {
				top: -$mobile-menu_vertical-accordeon--bar-thickness - $mobile-menu_vertical-accordeon--bar-space;
				/* autoprefixer: off */
				transition-property: top, transform;
			}

			&:after {
				bottom: -$mobile-menu_vertical-accordeon--bar-thickness - $mobile-menu_vertical-accordeon--bar-space;
				/* autoprefixer: off */
				transition-property: bottom, transform;
			}
		}

		.b-mobile-menu__burger-ingredients-middle {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: $mobile-menu_vertical-accordeon--button-color;
			transition: opacity 0s $mobile-menu_vertical-accordeon--transistion-duration;
			will-change: transform;
			display: none;
		}

		&.is-expand {
			position: relative;
			z-index: 500;
			background: none;

			.b-mobile-menu__burger-ingredients-middle {
				opacity: 0;
			}

			.b-mobile-menu__burger-ingredients {
				&:before,
				&:after {
					transition-delay: 0s, $mobile-menu_vertical-accordeon--transistion-duration;
				}

				&:before {
					top: 0;
					transform: rotate(45deg);
				}

				&:after {
					bottom: 0;
					transform: rotate(-45deg);
				}
			}
		}
	}

	.b-mobile-menu__panel {
		display: none;
		position: fixed;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		z-index: 100;
		background: $mobile-menu_vertical-accordeon--panel-bg;
		padding-top: 138px;
		overflow: auto;
		@include rem(padding, 55px 20px 37px);

		&::-webkit-scrollbar {
			background: transparent;
			width: 8px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: #555;
			background-color: rgba(0, 0, 0, 0.4);
		}
	}

	.b-mobile-menu__item {
		display: block;
		vertical-align: top;
		margin-bottom: 15px;
	}

	.b-mobile-menu__item--search {
		padding: 0 20px;
		text-align: center;
		padding-left: 15px;
		padding-right: 15px;

		.search.search--inner .search__input {
			width: 100%;
		}

		.search {
			display: inline-block;
			width: 100%;
		}

		&--menu {
			width: 100%;
		}
	}

	.b-mobile-menu__item--menu {
		width: 100%;
	}

	.b-nav__list {
		.b-nav__list {
			display: none;
		}

		&.is-expand {
			display: block;
			border-top: 1px solid $mobile-menu_vertical-accordeon--controller-color-hover;
			margin-top: 4px;
		}

		&--lvl1 {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			height: auto;
		}

		&--lvl2 {
			padding-top: 14px;
		}

		&--lvl3 {
			padding-top: 14px;
		}
	}

	.b-nav__item {
		position: relative;
		width: 100%;
		@include rem(margin-bottom, 15px);

		&:before {
			left: -16px;
			font-size: 25px;
			top: 5px;
		}

		.b-nav__link {
			@include font-size($mobile-menu_vertical-accordeon--font-size);
			line-height: 1;
		}

		&--lvl1 {
			@include rem(margin-bottom, 20px);

			.b-nav__link {
				letter-spacing: 0.05em;
				flex: 1;
				line-height: 1.3;
			}
		}

		&--lvl2 {
			width: auto;
			margin-bottom: 8px;

			.b-nav__link {
				@include font-size(16px);
				line-height: 17px;
			}
		}

		&--main {
			max-width: 370px;
			margin-bottom: 11px;

			.b-nav__link {
				@include font(300, 'BanderaPro');
				@include font-size($mobile-menu_vertical-accordeon--main-font-size);
				@include rem(line-height, 23px);
				letter-spacing: 0.1em;
				padding: 0;
				max-width: unset;
				padding-bottom: 5px;
				border-bottom: 1px solid transparent;

				&.current {
					border-bottom-color: $mobile-menu_vertical-accordeon--link-color-active;

					&::before {
						content: '#';
						@include font(300, 'BanderaPro');
						@include font-size(25px);
						letter-spacing: 0.1em;
						line-height: 90%;
						width: 22px;
						display: inline-block;
						position: absolute;
						left: -16px;	// -(width + 5px)
						color: $mobile-menu_vertical-accordeon--link-color-active;
					}
				}
			}

			.b-nav__link-wrapper {
				padding-bottom: 0;
			}

			& + :not(.b-nav__item--main) {
				margin-top: 15px;
			}
		}
	}

	.b-nav__controller {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		border: 4px solid;
		border-color: transparent;
		border-top-color: $mobile-menu_vertical-accordeon--controller-color;
		border-right-color: $mobile-menu_vertical-accordeon--controller-color;
		transform: rotate(135deg);
		transition: border-color 0.2s ease;
		cursor: pointer;
		margin: auto 0;
		@include rem(margin-right, 17px);
		@include rem(width, 7px);
		@include rem(height, 7px);

		&.is-expand {
			border-color: $mobile-menu_vertical-accordeon--link-color-hover;
		}
	}

	.b-nav__link-wrapper {
		position: relative;
		display: flex;

		&:active {
			.b-nav__link--lvl1 {
				color: $mobile-menu_vertical-accordeon--link-color-active;
			}

			.b-nav__controller {
				&:before,
				&:after {
					background-color: $mobile-menu_vertical-accordeon--controller-color-active;
				}
			}
		}

		&--lvl2 {
			display: flex;
		}
	}

	.b-nav__link {
		display: inline-block;
		color: $mobile-menu_vertical-accordeon--link-color;
		text-decoration: none;
		line-height: 1;
		@include rem(padding-right, 15px);
		@include font-size($mobile-menu_vertical-accordeon--font-size);
		@include font(400);
		line-height: 20px;

		&.is-expand {
			color: $mobile-menu_vertical-accordeon--link-color-hover;
		}

		&.current {
			color: $mobile-menu_vertical-accordeon--link-color-hover;

			& + .b-nav__controller {
				border-color: $mobile-menu_vertical-accordeon--controller-color-hover;
			}
		}
	}

	.b-nav__link--lvl1 {
		background-color: $mobile-menu_vertical-accordeon--link-lvl1-bg;
		color: $mobile-menu_vertical-accordeon--link-color;
		text-transform: uppercase;

		&:active {
			color: $mobile-menu_vertical-accordeon--link-color-active;
		}
	}

	.b-nav__link--lvl2 {
		@include rem(padding-left, 30px);
		color: $mobile-menu_vertical-accordeon--link-color;

		&.current {
			color: $mobile-menu_vertical-accordeon--link-color-hover;

			&:before {
				content: '';
				display: block;
				height: 1px;
				width: 100%;
				position: absolute;
				bottom: -4px;
				background-color: $menu_vertical--link-color-expand-hover;
			}
		}
	}

	.b-nav__link--lvl3 {
		@include rem(padding-left, 45px);
	}

	.b-nav__link--lvl4 {
		@include rem(padding-left, 60px);
	}
}

@if ($responsive) {
	@media (min-width: $breakpoint-xs) and (max-width: $breakpoint-md) {
		.b-mobile-menu.b-mobile-menu--vertical-accordion {
			.b-nav__link-wrapper--lvl1 {
				padding-bottom: 4px;
			}

			.b-nav__list--lvl1 {
				height: 71vh;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
			}

			.b-nav__controller {
				margin-left: auto;
				margin-right: 0;
			}

			.b-nav__link--lvl2 {
				padding-left: 0;
				@include rem(padding-left, 21px);
			}

			.b-nav__item--lvl1 {
				width: 45%;
			}

			.b-nav__item--main {
				width: 100%;
				max-width: 100%;
			}

			.b-nav__item:not(.b-nav__item--main) {
				@include rem(margin-top, 20px);
				margin-bottom: 0;

				.b-nav__link {
					max-width: 251px;
				}
			}
		}
	}
}
