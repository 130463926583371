$menu--font-size: 15px;
$menu--link-color: $base-text-color;
$menu--link-color-expand-hover: $color-deep-sky-blue;
$menu--link-bg-expand-hover: $color-deep-sky-blue;
$menu--link-color-active: $color-deep-sky-blue;
$menu--link-bg-active: $color-deep-sky-blue;
$menu--link-color-current: $color-deep-sky-blue;
$menu--link-bg-current: $base-text-color;
$menu_vertical--font-size: 13px;
$menu_vertical--link-color: $base-text-color;
$menu_vertical--link-color-expand-hover: $color-deep-sky-blue;
$menu_vertical--link-bg-expand-hover: $color-deep-sky-blue;
$menu_vertical--link-color-active: $color-deep-sky-blue;
$menu_vertical--link-bg-active: $color-deep-sky-blue;
$menu_vertical--link-color-current: $color-deep-sky-blue;
$menu_vertical--link-bg-current: $base-text-color;
$menu--list-lvl1-bg: $color-eallery;
$menu--list-lvl2-bg: $color-eallery;
$menu--list-lvl3-bg: #ededed;
$menu--list-lvl4-bg: #dcdcdc;
$menu--sub-space-bg: #aeaeae;
$menu--icon-color: #444444;
$menu--icon-color-hover: #fff;

@if ($contrast) {
	$menu--link-color: $base-text-color;
	$menu--link-color-expand-hover: $color-deep-sky-blue;
	$menu--link-bg-expand-hover: $color-deep-sky-blue;
	$menu--link-color-active: $color-deep-sky-blue;
	$menu--link-bg-active: $color-deep-sky-blue;
	$menu--link-color-current: $color-deep-sky-blue;
	$menu--link-bg-current: $base-text-color;
	$menu_vertical--link-color: $base-text-color;
	$menu_vertical--link-color-expand-hover: $color-deep-sky-blue;
	$menu_vertical--link-bg-expand-hover: $color-deep-sky-blue;
	$menu_vertical--link-color-active: $color-deep-sky-blue;
	$menu_vertical--link-bg-active: $color-deep-sky-blue;
	$menu_vertical--link-color-current: $color-deep-sky-blue;
	$menu_vertical--link-bg-current: $base-text-color;
	$menu--list-lvl1-bg: #ededed;
	$menu--list-lvl2-bg: #e1e1e1;
	$menu--list-lvl3-bg: #e6e3e3;
	$menu--list-lvl4-bg: #dcdcdc;
	$menu--sub-space-bg: #aeaeae;
	$menu--icon-color: $color-deep-sky-blue;
	$menu--icon-color-hover: #fff;
}

//Menu items equal width
/*
  Если элементы первого уровня равны по ширине,
  т.е. ширина будет указана в процентах относительно ширины родительского контейнера,
  ставим true, в противном случае false.
*/
$menu-equal-width: true;

@if $menu-equal-width {
	@for $i from 1 through 15 {
		.b-nav__list--lvl1[data-items="#{$i}"] {
			.b-nav__item--lvl1 {
				width: 100% / $i;
			}
		}
	}
}

.b-nav {
	position: relative;

	&.is-fixed {
		position: fixed;
		top: 0;
		margin: 0;
		z-index: 999;
	}
}

// check
// .b-nav__list--lvl1 {
// 	margin-top: 0;
// 	margin-bottom: 0;
// 	padding-left: 0;
// }

.b-nav__item {
	display: block;
}

.b-nav__item--more {
	cursor: pointer;
}

.b-nav--left {
	position: relative;
	width: 100%;
}
