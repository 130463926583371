.b-slider-wrapper--gallery {
	.b-slider-wrapper__container {
		overflow: hidden;
		width: 100vw;
		left: 50%;
		transform: translateX(-50%);
		max-width: 1920px;
		position: relative;

		&--auto {
			max-width: unset;
			width: 100%;
			left: 0;
			transform: none;
		}

		&--horizontal {
			.b-slider-item {
				&__image {
					@media (min-width: 1800px) {
						height: 330px;
					}
				}
			}
		}

		&--vertical {
			.b-slider-item {
				&__image {
					height: 100%;
					max-height: 400px;
				}
			}
		}
	}

	// .b-slider {
	// 	.swiper-scrollbar {
	// 		bottom: 47px;
	// 		left: 0;
	// 		z-index: 3;
	// 		width: 100%;
	// 		height: 1px;
	// 		background-color: rgba($color-white, 0.3);

	// 		.swiper-scrollbar-drag {
	// 			background-color: $color-white;
	// 		}

	// 		&:not(.swiper-scrollbar-lock) {
	// 			& ~ .b-slider-gradient {
	// 				display: block;

	// 				&:before, &:after {
	// 					content: '';
	// 					display: block;
	// 					position: absolute;
	// 					top: 0px;
	// 					width: 147px;
	// 					height: 100%;
	// 					z-index: 2;
	// 					background: linear-gradient(90deg, #16111E 43.34%, rgba(22, 17, 30, 0) 100.79%);
	// 				}

	// 				&:before {
	// 					left: 0;
	// 					transform: translateX(-59px);
	// 				}

	// 				&:after {
	// 					right: 0;
	// 					transform: matrix(-1, 0, 0, 1, 0, 0) translateX(-59px);
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	.b-slider-gradient {
		display: block;

		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
			top: 0px;
			width: 147px;
			height: 100%;
			z-index: 2;
			background: linear-gradient(90deg, #16111E 43.34%, rgba(22, 17, 30, 0) 100.79%);
		}

		&:before {
			left: 0;
			transform: translateX(-59px);
		}

		&:after {
			right: 0;
			transform: matrix(-1, 0, 0, 1, 0, 0) translateX(-59px);
		}
	}

	.l-slider-button-wrapper {
		position: absolute;
		width: 100%;
		height: 0;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;
		max-width: 1920px;
		margin: auto;
	}

	.b-slider-button {
		position: absolute;
		top: 0;
		transform: translateY(-50%);
		background-color: $slider-nav--bg;
		color: $slider-nav--color;
		border: 1px solid rgba($slider-nav--border, 0.3);
		border-radius: 50%;
		width: 40px;
		height: 40px;
		transition: color 0.2s ease, opacity 0.2s ease, border-color 0.2s ease;
		@include webfont-icon($webfont-icon--i-see-down);
		font-size: 5px;

		&:before {
			content: '';
			display: inline-block;
		}

		&.swiper-button-disabled {
			opacity: 0.3;
		}

		&:not(.swiper-button-disabled) {
			cursor: pointer;

			&:hover {
				background-color: $slider-nav--bg-hover;
				color: $slider-nav--color-hover;
			}

			&:active {
				background-color: $slider-nav--bg-active;
				color: $slider-nav--color-active;
			}
		}
	}

	.b-slider-button--prev {
		left: 2%;
		@include webfont-icon($webfont-icon--i-see-down);

		&::before {
			transform: rotate(90deg);
		}
	}

	.b-slider-button--next {
		right: 2%;
		@include webfont-icon($webfont-icon--i-see-down);

		&::before {
			transform: rotate(270deg);
		}
	}

	.b-slider-item {
		outline: none;
		width: auto !important;
		margin: auto;

		&:first-child {
			margin-left: auto;
		}

		&:last-child {
			margin-right: auto;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			outline: none;
			background: rgba(22, 17, 30, 0.75);
			transition: background 0.2s ease;
		}

		&__digit {
			@include font(400, 'BanderaPro');
			@include font-size(51px);
			@include rem(line-height, 61px);
			// letter-spacing: 0.1em;
			color: $color-deep-sky-blue;
			margin: 0;
			opacity: 0;
			visibility: hidden;
			margin-left: 22px;
			margin-bottom: 6px;
			transition: opacity 0.2s ease;
		}

		&__unit {
			@include font(300, 'BanderaPro');
			@include font-size(35px);
			line-height: 1;
		}

		&__image {
			height: 330px;
		}

		&:hover {
			&::before {
				background: none;
			}

			.b-slider-item__digit {
				visibility: visible;
				opacity: 1;
			}
		}

		&.swiper-slide-active {
			&::before {
				background: none;
			}

			.b-slider-item__digit {
				visibility: visible;
				opacity: 1;
			}
		}

		@media (hover), (min-width:0\0) {
			&.swiper-slide-active {
				&::before {
					background: none;
				}

				.b-slider-item__digit {
					visibility: visible;
					opacity: 1;
				}

				// &:hover {
				// 	&::before {
				// 		background: none;
				// 	}

				// 	.b-slider-item__digit {
				// 		visibility: visible;
				// 		opacity: 1;
				// 	}
				// }
			}
		}

		// @media (min-width: 799px) {
		// 	&:hover {
		// 		&::before {
		// 			background: none;
		// 		}

		// 		.b-slider-item__digit {
		// 			visibility: visible;
		// 			opacity: 1;
		// 		}
		// 	}
		// }
		//
		// @media (max-width: 799px) {
		// 	&.swiper-slide-active {
		// 		&::before {
		// 			background: none;
		// 		}

		// 		.b-slider-item__digit {
		// 			visibility: visible;
		// 			opacity: 1;
		// 		}
		// 	}
		// }
	}
}

@if ($responsive) {
	@media (max-width: 1800px) {
		.b-slider-wrapper--gallery {
			.b-slider-wrapper__container {
				&--horizontal {
					.b-slider-item__image {
						max-height: 245px;
					}
				}
			}
		}
	}

	@include breakpoint(lg) {
		.b-slider-wrapper--gallery {
			.b-slider {
				.swiper-scrollbar {
					bottom: 39px;
				}
			}

			.b-slider-item {
				width: unset !important;
			}
		}
	}

	@include breakpoint(sm) {
		.b-slider-wrapper--gallery {
			// .b-slider {
			// 	.swiper-scrollbar:not(.swiper-scrollbar-lock) {
			// 		& ~ .b-slider-gradient {
			// 			&:before, &:after {
			// 				width: 100px;
			// 			}

			// 			&:before {
			// 				transform: translateX(-50%);
			// 			}

			// 			&:after {
			// 				transform: matrix(-1, 0, 0, 1, 0, 0) translateX(-50%);
			// 			}
			// 		}
			// 	}
			// }

			.b-slider-gradient {
				&:before, &:after {
					width: 100px;
				}

				&:before {
					transform: translateX(-50%);
				}

				&:after {
					transform: matrix(-1, 0, 0, 1, 0, 0) translateX(-50%);
				}
			}

			.b-slider-item {
				&__digit {
					@include font-size(45px);
					line-height: 1;
				}

				&__image {
					max-height: 300px;
				}

				&.touched {
					&::before {
						background: none;
					}

					.b-slider-item__digit {
						visibility: visible;
						opacity: 1;
					}
				}
			}

			.b-slider-wrapper__container {
				&--horizontal {
					.b-slider-item__image {
						max-height: 200px;
					}
				}

				&--vertical {
					.b-slider-item__image {
						max-height: 300px;
					}
				}
			}
		}
	}

	@include breakpoint(xs) {
		.b-slider-item {
			&__digit {
				text-align: center;
			}
		}
	}
}
