// figma
@import './figma/tokens-typography.scss';

%text--body,
.text--body {
	@include font-size(20px);
	@include font(400);
	line-height: 150%;
	font-style: normal;

	@if ($responsive) {
		@include breakpoint(lg) {
			@include font-size($base-font-size);
		}
	}
}

%arial,
.arial {
	font-family: Arial, Helvetica, sans-serif;
}

%caption {
	@include font-size(16px);
	font-weight: bold;
	color: $base-text-color;
	border-bottom: 2px solid $base-text-color;

	.caption__units,
	.b-caption__units {
		font-weight: normal;
	}
}

p {
	@include font(400);
	margin-bottom: 17px;

	@if ($responsive) {
		@include breakpoint(sm) {
			@include rem(line-height, 18px);
		}
	}
}
