$title--text-color: $color-white;
$title--link-color: $link--fn-default-color;
$title--link-color--hover: $link--fn-hover-color;
$title--link-color--active: $link--fn-active-color;

@if ($contrast) {
	$title--text-color: $color-white;
}

.b-report-title {
	line-height: 16px;
	letter-spacing: 0.1em;
	@include font-size(18px);
	@include font(300);
	text-align: center;

	&__link {
		color: $title--link-color;
		text-decoration: none;

		&:visited {
			color: $title--link-color;
		}

		&:hover,
		&:visited:hover {
			color: $title--link-color--hover;
		}

		&:active,
		&:visited:active {
			color: $title--link-color--active;
		}
	}

	@if ($responsive) {
		@include breakpoint(lg) {
			@include font-size(12px);
		}

		@include breakpoint(sm) {
			@include font-size(11px);
		}
	}
}
