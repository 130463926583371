// figma
@import './../../styles/figma/tokens-functional.scss';

//text
$base-text-color: $color-white;
$color-text-1: $base-text-color;

//errors
$error-text-color: #c7191a;
$error-form-bg: #fea8a6;

// figma
$project--main-color: $color-white;

//links
$link-default-color: $color-white;
$link-hover-color: $color-deep-sky-blue;
$link-active-color: $color-dodger-blue;
$link-visited-color: $color-white;

//pseudo links (functional page)
$link--fn-default-color: $color-white;
$link--fn-hover-color: $color-deep-sky-blue;
$link--fn-active-color: $color-dodger-blue;
$link--fn-visited-color: $color-white;

//buttons
$btn--bg: $color-blackcurrant;
$btn--bg-hover: $color-deep-sky-blue;
$btn--bg-active: $color-blackcurrant;
$btn--bg-disabled: rgba($color-white, 0.3);
//
$btn--color: $color-white;
$btn--color-hover: $color-blackcurrant;
$btn--color-active: $color-dodger-blue;
$btn--color-disabled: rgba($color-white, 0.3);
// additional
$btn--border-color: $color-white;
$btn--border-color-hover: $color-deep-sky-blue;
$btn--border-color-active: $color-blackcurrant;
$btn--border-color-disabled: rgba($color-white, 0.3);

// figma
//checkbox
$checkbox--color: $color-white;
$checkbox--color-hover: $color-deep-sky-blue;
$checkbox--color-checked: $color-dodger-blue;
$checkbox--color-partly: rgba($color-white, 0.3);
// additional
$checkbox--border-color: $color-white;
$checkbox--border-color-hover: $color-deep-sky-blue;
$checkbox--border-color-checked: $color-dodger-blue;
$checkbox--border-color-partly: rgba($color-white, 0.3);
//
$checkbox--bg: $color-white;
$checkbox--bg-hover: $color-white;
$checkbox--bg-checked: $color-white;
$checkbox--bg-partly: rgba($color-white, 0.3);

// xls, zoom, analysis buttons
$tools--color: $color-white;
$tools--color-hover: $color-deep-sky-blue;
$tools--color-active: $color-dodger-blue;

// tooltips
$t_tooltips--bg-color: $color-white;
$t_tooltips--color: $color-blackcurrant;

@if ($contrast) {
	$base-text-color: $color-white;
	$error-text-color: #ff0000;
	$error-form-bg: #fff;
	$link-default-color: $base-text-color;
	$link-hover-color: $color-deep-sky-blue;
	$link-visited-color: #252525;
	$btn--bg: #ededed;
	$btn--bg-hover: #484848;
	$btn--bg-active: #252525;
	$btn--color: #656565;
	$btn--color-hover: $color-white;
	$btn--color-active: $color-white;
	$tools--color: $color-white;
	$tools--color-hover: $color-black;
	$tools--color-active: $color-deep-sky-blue;
}
