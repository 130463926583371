.b-search-result {
	@include rem(margin-top, 71px);

	&__item {
		padding-top: 53px;
		@include rem(border-top-width, 1px);
		border-top-style: solid;

		&:first-child {
			padding-top: 0;
			border-top: 0;
		}
	}

	&__title {
		@include rem(margin-bottom, 35px);
		@include rem(line-height, 26px);
	}

	&__text {
		@include rem(margin-bottom, 24px);
		@include font-size(22px);
		@include rem(line-height, 33px);
		font-weight: 300;

		.highlight {
			font-weight: 400;
		}

		@if ($responsive) {
			@include breakpoint(lg) {
				font-size: initial;
			}
		}
	}

	&__link {
		font-weight: 400;
		color: $link-default-color;
		text-decoration: none;
		letter-spacing: normal;

		&:visited {
			color: $link-visited-color;
		}

		&:hover,
		&:visited:hover {
			color: $link-hover-color;
		}

		&:active,
		&:visited:active {
			color: $link-active-color;
		}
	}

	&__loading-trigger {
		width: 100%;

		&.is-finidhed {
			display: none;
		}

		&.is-loading {
			height: 40px;
		}
	}
}
