// figma
@import './../../styles/figma/tokens-colors.scss';

// Цвета
$color-black: #000000;
$color-onyx: #111111;
$color-dark-jungle: #222222;
$color-mine-shaft: #333333;
$color-tundora: #444444;
$color-davys-grey: #555555;
$color-ironside-gray: #666666;
$color-tapa-gray: #777777;
$color-taupe-gray: #888888;
$color-mountain-mist: #999999;
$color-dark-gray: #aaaaaa;
$color-gray: #bbbbbb;
$color-celeste: #cccccc;
$color-light-gray: #dddddd;
$color-eallery: #eeeeee;
$color-white: #ffffff;
$color-navy-blue: #0079c1;
$color-prussian-blue: #00426a;
$color-error: #e24831;
$color-carrot-orange: #f39318;

.color-navy-blue {
	color: $color-navy-blue;
}
$color-sky-blue: #87ceeb;
$color-blackcurrant: #16111e;
$color-deep-sky-blue: #009fe3;
$color-navy-blue: #005fcf;
$color-medium-violet-red: #c80084;
$color-radial: radial-gradient(94.63% 271.84% at 55.63% 47.54%, #01012E 0%, #0D012F 51.56%, #240330 100%);
$color-dodger-blue: #358ef7;
$color-pacific-blue: #009fe3;
$color-dim-gray: #666666;
