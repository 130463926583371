.l-layout-wrapper--1 {
	.browser-ie & {
		height: 100%;
	}

	.l-layout-wrapper__header.is-fixed {
		position: static;
		top: 0;
		z-index: 10;
		width: $site-width;
	}

	.l-header-top-line {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include rem(padding, 44px 0);

		&__logo {
			display: block;
			position: relative;
			z-index: 10;
		}

		&__title {
			flex-shrink: 0;
			flex-grow: 0;
			text-align: center;
			text-transform: uppercase;
			// выраванивание по центру экрана
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			z-index: 4;
		}

		&__tools {
			display: flex;
			justify-content: flex-end;
		}
	}

	.l-header-top-nav {
		display: none;

		&__logo {
			flex-shrink: 0;
			margin-right: 12px;
		}

		&__menu {
			flex-grow: 1;
			flex-shrink: 1;
		}
	}

	.l-page {
		width: 100%;

		&__nav-breadcrumbs {
			width: 100%;
			max-width: calc(100% - 90px); // минус ширина page-nav'a
		}
	}

	.l-buttons-area {
		display: flex;
		align-items: center;

		&__item + .l-buttons-area__item {
			@include rem(margin-left, 50px);
		}

		&__search {
			.search {
				width: auto;
				display: block;
			}
		}

		&__mobile-nav-panel {
			display: none;
		}
	}

	.l-footer {
		display: flex;
		@include rem(padding, 31px 50px);
		position: relative;
    z-index: 1;

    &__inner {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
    }

    &__nav {
      text-align: right;
    }
	}

	.l-page-nav {
		height: 0px;
    min-height: 0px;

		.b-fixed-area {
			flex-grow: 0;
			flex-shrink: 0;
			position: fixed;
			right: 0;
			right: 84px;
			z-index: 2;
			top: 198px;
		}

		.b-now-static-area {
			position: relative;
			bottom: 0;
			width: 100%;
		}
	}

	.l-header {
		background-color: $color-blackcurrant;
		transition: background-color 0.2s ease;
	}

	.l-buttons-area__menu {
		position: relative;
		z-index: 20;
	}
}

@if ($responsive) {
	.l-layout-wrapper--1 {
		.l-layout-wrapper__header.is-fixed {
			width: 100%;
			max-width: $site-width;
			min-width: $site-min-width;
		}
	}

	@include breakpoint(lg) {
		.l-layout-wrapper--1 {
			.l-header-top-line {
				@include rem(padding, 30px 0);
			}

			.l-buttons-area {
				&__item + .l-buttons-area__item {
					@include rem(margin-left, 30px);
				}
			}

			.l-page-nav .b-fixed-area {
				top: 138px;
			}
		}
	}

	@include breakpoint(md) {
		.l-layout-wrapper--1 {
			.l-header-top-line {
				&__logo {
					display: block;
				}
			}

			.l-header-top-nav__logo {
				display: none;
			}

			.l-page-nav .b-fixed-area {
				right: 46px;
			}
		}
	}

	@media (max-width: 1080px) {
		.l-layout-wrapper--1 {
			.l-page-nav {
				width: 100%;
				left: 0;
				bottom: 0;
				height: 50px;
				min-height: 50px;

				.b-fixed-area {
					right: 0;
					left: 0;
					bottom: 0;
					top: initial;
					padding: 0 20px;
				}
			}
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper--1 {
			.l-layout-wrapper__header {
				padding: 0 5.5vw;
			}

			.l-page {
				flex-direction: column;
			}

			.l-page__logo {
				margin-bottom: $grid-gutter-width;
			}

			.l-page__sidebar {
				flex-basis: auto;
				width: auto;
			}

			.l-page__nav-breadcrumbs {
				width: 100%;
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}

			.l-header-top-line {
				padding: 0;
				padding-top: 11px;
				flex-wrap: wrap;

				&__title {
					order: 1;
					width: 100%;
					flex-basis: auto;
					margin-top: 11px;
					position: relative;
					left: 0;
					transform: none;
				}
			}

			.l-header-top-nav {
				display: none;
			}

			.l-footer {
				padding: 19px $grid-gutter-width;

				&__nav {
					margin-left: auto;
				}
			}

			.l-buttons-area {
				&__item + .l-buttons-area__item {
					@include rem(margin-left, 17px);
				}
			}
		}
	}

	@include breakpoint(xs) {
		.l-layout-wrapper--1 {
			.l-page__nav {
				flex-direction: column;
				align-items: flex-start;
			}

			.l-page__nav-breadcrumbs {
				max-width: 100%;
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}

			.l-buttons-area__menu {
				display: none;
			}

			.l-buttons-area__mobile-nav-panel {
				display: block;
			}
		}
	}
}
