.b-useful-links--static {
	.b-useful-links {
		&__title {
			@include font-size(17px);
			@include rem(padding-bottom, 10px);
			border-bottom: 1px solid;
		}

		&__list {
			@include rem(padding-top, 10px);
		}
	}
}
