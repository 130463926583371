$download-arrow-width: 80px;
$download-arrow-height: 80px;

.button--excel {
	color: $tools--color;
	display: inline-block;
	background-color: transparent;
	@include webfont-icon($webfont-icon--file-excel);
	font-size: 0;
	@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */
	margin: 1px 6px;
	padding: 0;

	&:before {
		font-size: 20px;
	}

	@include hover {
		color: $tools--color-hover;
		border-color: $tools--color-hover;
	}

	&:active {
		color: $tools--color-active;
		border-color: $tools--color-active;
	}
}

.button--show-only-in-mobile {
	display: none;
}

@if ($responsive) {
	@include breakpoint(sm) {
		.button--show-only-in-mobile {
			display: initial;
		}
	}
}

.download-arrow {
	position: fixed;
	z-index: 999;
	display: none;
	height: $download-arrow-height;
	width: $download-arrow-width;
	opacity: 0;
	@include svg($download-arrow-width, $download-arrow-height, '/images/icons/download_xls.svg');
	background-repeat: no-repeat;
	background-position-x: 0;
	background-position-y: 0;

	.browser-ie & {
		bottom: 75px;
		left: 50%;
		transform: translateX(-50%);
		background-image: url('/images/icons/download_xls.svg');
		background-size: $download-arrow-width, $download-arrow-height;
	}

	//For Chrome and Edge
	.browser-chrome & {
		bottom: 10px;
		left: 20px;
	}

	.browser-safari & {
		top: 2px;
		right: 140px;
		margin-right: calc(-1 * (100vw - 100%)); //margin for scroll
		transform: rotate(180deg) translate(-50%);
	}

	.browser-firefox & {
		top: 2px;
		right: 156px;
		margin-right: calc(-1 * (100vw - 100%)); //margin for scroll
		transform: rotate(180deg) translate(-50%);
	}

	.browser-yabrowser & {
		bottom: auto;
		left: auto;
		top: 10px;
		right: 65px;
		margin-right: calc(-1 * (100vw - 100%)); //margin for scroll
		transform: rotate(180deg) translate(-50%);
	}

	.browser-opera & {
		bottom: auto;
		left: auto;
		top: 10px;
		right: 55px;
		margin-right: calc(-1 * (100vw - 100%)); //margin for scroll
		transform: rotate(180deg) translate(-50%);
	}
}
