$captcha_base--icon-color: $figma-color--project-color;
$captcha_base--icon-color-hover: $figma-color--project-color;
$captcha_base--icon-color-active: $figma-color--project-color;
$captcha_base--border-color: #aaaaaa;

@if ($contrast) {
	$captcha_base--icon-color: $color-white;
	$captcha_base--icon-color: $color-ironside-gray;
	$captcha_base--icon-color-hover: $color-ironside-gray;
	$captcha_base--icon-color-active: $color-ironside-gray;
	$captcha_base--border-color: #aaaaaa;
}

.b-captcha {
	&__reload-btn {
		background-color: transparent;
		color: $captcha_base--icon-color;
		padding: 0;
		margin-right: 15px;
		@include webfont-icon($webfont-icon--reload_2);

		&:before {
			display: block;
			@include font-size(20px);
		}

		@include hover {
			color: $captcha_base--icon-color-hover;
		}

		&:active {
			color: $captcha_base--icon-color-active;
		}
	}

	&__data {
		display: flex;
		align-items: center;
	}

	&__image {
		border: 1px solid $captcha_base--border-color;
	}
}
