.error-page {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-image: url('/images/components/error-page/images/error-bg.jpg');
	background-position: center;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;

	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&:before {
		background: $color-black;
		mix-blend-mode: multiply;
		opacity: 0.45;
		z-index: 2;
	}

	&:after {
		background: linear-gradient(312.21deg, #DB216C 22.42%, #3F88FF 71.61%);
		mix-blend-mode: color;
		opacity: 0.35;
		z-index: 1;
	}

	&__inner {
		text-align: center;
		@include rem(max-width, 500px);
		position: relative;
		z-index: 2;
	}

	&__icon {
		width: 50px;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);

		svg {
			fill: $project--main-color;
		}
	}

	&__title {
		margin-bottom: 26px;
		@include font(400, 'BanderaPro');
		@include font-size(144px);
    line-height: 123.05%;
		font-feature-settings: 'pnum' on, 'lnum' on;
		-webkit-text-stroke: 2px $color-white;
		text-stroke: 2px $color-white;
		color: transparent;
	}

	&__text {
		line-height: 128.91%;
		margin-bottom: 0;
	}

	&__link-wrap {
		@include rem(margin-top, 13px);
		margin-bottom: 0;
	}

	&__link {
		color: $link-default-color;

		&:visited {
			color: $link-default-color;
		}

		&:hover,
		&:visited:hover {
			color: $link-hover-color;
			text-decoration: none;
		}

		&:active,
		&:visited:active {
			color: $link-active-color;
		}
	}
}

.body--page-error {
	.content-area {
		padding: 0;
		height: 100%;
	}

	.l-layout-wrapper .l-page {
		flex-direction: row;
		padding: 0;
	}

	.l-page__content {
		padding: 0;
	}

	.l-layout-wrapper--1 .l-header {
		margin-bottom: 0;
	}
}

@include breakpoint(lg) {
	.error-page {
		&__inner {
			@include rem(max-width, 380px);
		}
	}
}

@include breakpoint(xs) {
	.error-page {
		@include rem(padding, 20px);

		&__icon {
			width: 40px;
		}

		&__title {
			@include font-size(90px);
		}

		&__link-wrap {
			@include rem(margin-top, 18px);
		}
	}
}
