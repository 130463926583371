.note {
	display: block;
	@include font(300);
	@include font-size(14px);
	line-height: 14px;
	margin-top: 10px;
	margin-bottom: 70px;
	font-style: normal;

	div + div {
		@include rem(margin-top, 6px);
	}

	@if ($responsive) {
		@include breakpoint(lg) {
			@include font-size(12px);
		}
		@include breakpoint(xs) {
			margin-bottom: 30px;
		}
	}
}
