$menu--panel-bg: $color-radial;

$menu--arrow-color: $base-text-color;
$menu--arrow-color-hover: $color-deep-sky-blue;
$menu--arrow-color-active: $color-deep-sky-blue;

$menu--burger-color: $base-text-color;
$menu--burger-color-hover: $color-deep-sky-blue;
$menu--burger-color-active: $color-dodger-blue;

.b-nav--top.b-nav--horizontal-wide-page {
	.b-nav__burger {
		display: block;
		width: 32px;
		height: 11px;
		text-decoration: none;
		position: relative;
		margin: 0 auto;
		border-top: 1px solid currentColor;
		color: $menu--burger-color;
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: currentColor;
		}

		&:before {
			top: 12px;
			visibility: hidden;
			opacity: 0;
		}

		&:after {
			top: 11px;
		}

		@include hover {
			color: $menu--burger-color-hover;
		}

		&:active {
			color: $menu--burger-color-active;
		}

		&.is-expand {
			border-top-color: transparent;
			z-index: 7;

			&:before {
				transform: rotate(-45deg);
				top: 5px;
				visibility: visible;
				opacity: 1;
			}

			&:after {
				transform: rotate(45deg);
				top: 5px;
			}

			.b-nav__burger-text {
				display: none;
			}

			.b-nav__burger-text--close {
				display: none;
				margin-left: -9px;
			}
		}
	}

	.b-nav__burger-text {
		display: none;
		font-size: 13px;
		line-height: 16px;
		text-transform: uppercase;
		padding-top: 36px;

		&--close {
			display: none;
		}
	}

	.b-nav__panel {
		position: fixed;
		display: none;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 6;
	}

	.b-nav__panel-inner {
		width: 100%;
		height: 100%;
		background: $menu--panel-bg;
	}

	.l-nav-cols {
		padding: 138px 0 5vh;
		max-width: $site-width;
		margin: auto;

		.row {
			justify-content: space-between;
		}
	}

	.os-scrollbar {
		z-index: 3;
	}

	.b-nav__list--lvl1 {
		width: 100%;
		height: 100%;
	}

	.b-nav__list--lvl2 {
		display: none;
		position: relative;
		// border-top: 1px solid $menu_vertical--link-color-expand-hover;
		margin-top: 4px;
		padding-top: 15px;

		&.is-expand {
			display: block;
		}

		.b-nav__list {
			display: none;

			&.is-expand {
				display: block;
			}
		}
	}

	.b-nav__list--lvl3 {
		margin-left: 15px;
	}

	.b-nav__item {
		display: block;
		float: left;
		clear: left;

		&.main {
			.b-nav__link {
				@include font(300, 'BanderaPro');
				@include font-size(32px);
				@include rem(line-height, 35px);
				max-width: 370px;
				letter-spacing: 0.1em;
				padding: 0;
				border-bottom: 1px solid transparent;

				&.current {
					border-bottom-color: $menu--link-color-current;

					&::before {
						content: '#';
						@include font(300, 'BanderaPro');
						@include font-size(36px);
						letter-spacing: 0.1em;
						line-height: 90%;
						width: 22px;
						display: inline-block;
						position: absolute;
						left: -27px;	// -(width + 5px)
						top: 8px;
						color: $menu--link-color-current;
					}
				}
			}

			.b-nav__link-wrapper {
				&:not(.has-submenu) {
					margin-left: 0;
				}
			}
		}
	}

	.b-nav__item--lvl1 {
		margin-bottom: 3em;

		& > .b-nav__link-wrapper {
			&:not(.has-submenu) {
				margin-left: 25px;
			}
		}
	}

	.b-nav__item--lvl2 {
		&:last-child {
			.b-nav__link {
				margin-bottom: 0;
			}
		}

		.b-nav__link-wrapper {
			transform: translateX(29px);
		}
	}

	.b-nav__link-wrapper {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		justify-content: flex-end;

		@include hover {
			.b-nav__link {
				color: $menu--link-color-expand-hover;
			}

			.b-nav__arrow {
				border-color: $menu--arrow-color-hover;
				// border-right-color: $menu--arrow-color-hover;

				&--lvl2 {
					border-color: transparent;
					border-top-color: $menu--arrow-color-hover;
					border-right-color: $menu--arrow-color-hover;
				}
			}
		}

		&:active {
			.b-nav__link {
				color: $menu--link-color-active;
			}

			.b-nav__arrow {
				border-color: $menu--arrow-color-active;
				// border-right-color: $menu--arrow-color-active;

				&--lvl2 {
					border-color: transparent;
					border-top-color: $menu--arrow-color-active;
					border-right-color: $menu--arrow-color-active;
				}
			}
		}

		// &:not(.has-submenu) {
		// 	margin-left: 25px;
		// }
	}

	.b-nav__link {
		position: relative;
		display: block;
		height: 100%;
		color: $menu--link-color;
		@include font-size($menu--font-size);
		line-height: 20px;
		transition: color 0.2s ease, background-color 0.2s ease;
		text-decoration: none;

		@include hover {
			color: $menu--link-color-expand-hover;
		}

		&:active {
			color: $menu--link-color-active;
		}

		&.current {
			color: $menu--link-color-current;

			& + .b-nav__arrow {
				border-color: $menu--link-color-current;
			}

			// & + .b-nav__arrow--lvl2 {
			// 	border-color: transparent;
			// 	border-top-color: $menu--link-color-current;
			// 	border-right-color: $menu--link-color-current;
			// }
		}
	}

	.b-nav__arrow {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 4px solid;
		border-color: $menu--arrow-color;
		// border-top-color: $menu--arrow-color;
		// border-right-color: $menu--arrow-color;
		transform: rotate(135deg);
		transition: border-color 0.2s ease;
		margin: auto 0;
		@include rem(margin-right, 17px);
		@include rem(width, 7px);
		@include rem(height, 7px);
		cursor: pointer;
		user-select: none;

		&.is-expand {
			border-color: $menu--arrow-color-active;
		}

		&--lvl2 {
			position: relative;
			right: 20px;
			display: inline-block;
			vertical-align: middle;
			border: 4px solid;
			border-color: transparent;
			border-top-color: $base-text-color;;
			border-right-color: $base-text-color;;
			transform: rotate(135deg);
			transition: border-color 0.2s ease;
			cursor: pointer;
			margin: auto 0;
			@include rem(margin-right, 17px);
			@include rem(width, 7px);
			@include rem(height, 7px);

			&.is-expand {
				border-color: $color-deep-sky-blue;
			}
		}
	}

	.b-nav__link--lvl1 {
		@include font-size($menu--font-size);
		text-transform: uppercase;
		letter-spacing: 0.05em;
		max-width: 249px;
	}

	.b-nav__link--lvl2 {
		@include font-size(16px);
		@include rem(margin, 0 29px 8px);
		line-height: 17px;
		color: $menu_vertical--link-color;
		margin-left: 0;

		&.current {
			color: $menu_vertical--link-color-expand-hover;

			&:before {
				content: '';
				display: block;
				height: 1px;
				width: 100%;
				position: absolute;
				bottom: -4px;
				background-color: $menu_vertical--link-color-expand-hover;
			}
		}

		&.has-submenu {
			order: 1;
		}
	}

	.b-nav__link--lvl3 {
		@include rem(margin, 0 29px 6px 0);
		@include font-size(14px);
	}

	.b-nav__group {
		padding: 0;

		&:first-child {
			flex-basis: 394px;
			max-width: 394px;
		}
	}
}

@if ($responsive) {
	@include breakpoint(lg) {
		.b-nav--top.b-nav--horizontal-wide-page {
			.l-nav-cols {
				padding-left: 50px;
				padding-right: 50px;
			}
		}
	}

	@include breakpoint(md) {
		.b-nav--top.b-nav--horizontal-wide-page {
			.l-nav-cols {
				padding-top: 85px;
			}

			.b-nav__group {
				&:first-child {
					flex-basis: 100%;
					max-width: 100%;

					.b-nav__item {
						&.main {
							@include rem(margin-bottom, 25px);

							.b-nav__link {
								@include font-size(28px);
								max-width: unset;
							}

							&:last-child {
								@include rem(margin-bottom, 45px);
							}
						}
					}
				}
			}
		}
	}

	@include breakpoint(sm) {
		.b-nav--top.b-nav--horizontal-wide-page {
			.b-nav__burger {
				@include hover {
					color: currentColor;
				}
			}
		}
	}
}
