.b-nav--top.b-nav--horizontal {
	.b-nav__list {
		background-color: $menu--list-lvl1-bg;
	}

	.b-nav__list--lvl1 {
		display: flex;
	}

	.b-nav__list--lvl2 {
		display: none;
		position: absolute;
		width: 100%;
		background-color: $menu--list-lvl2-bg;
		z-index: 20;
	}

	.b-nav__list--lvl3 {
		display: none;
		position: absolute;
		width: 100%;
		left: 100%;
		top: 0;
		min-height: 100%;
		background-color: $menu--list-lvl3-bg;
	}

	.b-nav__item {
		position: relative;
	}

	.b-nav__item--lvl1 {
		flex: 0 1 auto;
	}

	.b-nav__link {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		color: $menu--link-color;
		@include font-size($menu--font-size);
		text-decoration: none;
		transition: color 0.2s ease, background-color 0.2s ease;

		span {
			display: inline-block;
			width: 100%;
			vertical-align: middle;
			@include rem(line-height, 15px);
		}

		&.is-expand {
			color: $menu--link-color-expand-hover;
			background-color: $menu--link-bg-expand-hover;
		}

		@include hover {
			color: $menu--link-color-expand-hover;
			background-color: $menu--link-bg-expand-hover;
		}

		&:active {
			color: $menu--link-color-active;
			background-color: $menu--link-bg-active;
		}

		&.current {
			color: $menu--link-color-current;
			background-color: $menu--link-bg-current;
		}
	}

	.b-nav__link--lvl1 {
		@include rem(padding, 16px);
		display: flex;
		align-items: center;
	}

	.b-nav__link--lvl2 {
		@include rem(padding, 16px 30px 16px 15px);
	}

	.b-nav__link--lvl3 {
		@include rem(padding, 16px 30px 16px 15px);
	}

	.b-nav__item--lvl1 .has-submenu {
		> .b-nav__link {
			@include webfont-icon($webfont-icon--arrow-right, 'after');
			@extend .ie-after-underline;

			&:after {
				position: absolute;
				top: 0;
				@include rem(right, 15px);
				bottom: 0;
				margin: auto;
				@include font-size(10px);
				height: 10px;
			}
		}
	}

	.submenu-slide-left {
		.b-nav__link {
			@include rem(padding-left, 30px);
			@include rem(padding-right, 15px);
		}

		.has-submenu {
			> .b-nav__link {
				@include webfont-icon($webfont-icon--arrow-left, 'after');
				@extend .ie-after-underline;

				&:after {
					right: 100%;
					@include rem(margin-right, -20px);
				}
			}
		}
	}

	.is-slide-left {
		left: inherit;
		right: 100%;
	}
}
