.header-1 {
	@include font(300, 'BanderaPro');
	@include font-size(70px);
	@include rem(line-height, 84px);
	@include rem(margin, 0 0 $grid-gutter-width);
	letter-spacing: 0.05em;

	@if ($responsive) {
		@include breakpoint(lg) {
			@include font-size(46px);
			@include rem(line-height, 55px);
		}

		@include breakpoint(sm) {
			line-height: 1;
			@include font-size(36px);
		}
		@include breakpoint(xs) {
			line-height: 1;
			@include font-size(26px);
		}
	}
}

h1 {
	@extend .header-1;
}

.header-2 {
	@include font(400, 'BanderaPro');
	@include font-size(30px);
	@include rem(line-height, 36px);
	@include rem(margin, 44px 0 44px);
	color: $color-deep-sky-blue;
	letter-spacing: 0.05em;

	@if ($responsive) {
		@include breakpoint(sm) {
			line-height: 1;
			margin-bottom: 20px;
    	font-size: 24px;
		}
	}
}

h2 {
	@extend .header-2;
}

.header-3 {
	@include font(500, 'BanderaPro');
	@include font-size(26px);
	margin-bottom: 0.83em;
	margin-top: 0.83em;
}

h3 {
	@extend .header-3;
}

.header-4 {
	@include font(500, 'BanderaPro');
	@include font-size(20px);
	margin-bottom: 1em;
}

h4 {
	@extend .header-4;
}

.header-5 {
	@include font(300, 'BanderaPro');
	@include font-size(18px);
	margin-bottom: 1em;
}

h5 {
	@extend .header-5;
}

.header-6 {
	@include font(300, 'BanderaPro');
	@include font-size(16px);
	font-weight: bold;
	margin-bottom: 1.2em;
}

h6 {
	@extend .header-6;
}

.header-icon {
	display: flex;
	align-items: center;
	margin-bottom: 0.83em;

	&__icon {
		margin-right: 12px;
	}

	&__title {
		margin: 0;
	}
}

.h_special {
	font-weight: bold;
}

.header-fn {
	color: $color-black;
	font-weight: bold;
	@include font-size(18px);
}
