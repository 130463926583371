$download-center--row-bg-color: transparent;
$download-center--row-border-bottom-color: transparent;
$download-center--bg-accordion-article: #cdcdcd;
$download-center--download-btn-bg: #656565;
$download-center--download-btn-disabled-bg: #738d97;
$download-center--download-btn-color: #fff;
$download-center--section-title-border-color: #7d7d7d;
$download-center--section-title-color: #343434;
$download-center--section-col-border-color: #7d7d7d;

.b-download-center {
	width: 100%;

	&__body {
		margin-bottom: 10px;
	}

	&__btn + &__btn {
		@include rem(margin-left, 10px);
	}
}

// Стили для секций внутри аккордеона (Секции делящиеся на типы отчетов, языки, года)
.b-download-center-section {
	&:first-child .b-download-center-section__title {
		border-top: 0;
	}

	& + .b-download-center-section .b-download-center-section__title {
		border-top: 1px dotted $download-center--section-title-border-color;
	}

	&__title {
		border-bottom: 1px dotted $download-center--section-title-border-color;
		@include rem(padding, 18px 15px);
		// color: $download-center--section-title-color;
		@extend .figma-class--functional-title;
	}

	&__cols {
		@include rem(padding, 0 15px);
	}

	&__col {
		@include rem(padding, 18px 15px);
		border-right: 1px dotted $download-center--section-col-border-color;
	}

	&__col:last-child {
		border-right: 0;
	}

	&__col-title {
		// @include font-size(1rem);
		@extend .figma-class--funcional-subtitle;

		@include rem(margin-bottom, 15px);
	}
}

// Пункты в ЦЗ
.b-download-center-line {
	line-height: 1.3;
	margin-top: 0;
	@include rem(padding, 15px);
	background-color: $download-center--row-bg-color;
	border-bottom: 1px solid $download-center--row-border-bottom-color;
	display: flex;
	align-items: center;

	&__content a:not(.link--external) {
		text-decoration: none;
		color: $link--fn-default-color;

		&:visited {
			color: $link--fn-default-color;
		}

		@include hover {
			color: $link--fn-hover-color;
			text-decoration: underline;
		}

		&:active,
		&:visited:active {
			color: $link--fn-active-color;
		}
	}

	&__action + &__content {
		padding-left: 0.9em;
	}

	// Внутри аккордеона
	&--sub {
		@include font-size(1rem);
		@include rem(padding, 5px 0);
		background: transparent;
		border-bottom: none;
	}

	// В виде кнопки аккордеона
	&--accordion {
		padding: 0;
		margin: 0;

		& > .b-download-center-line__action {
			width: 100%;
		}

		.b-accordion-functional {
			text-align: left;
		}

		.b-accordion-functional__header {
			position: relative;
			width: 100%;

			.b-checkbox {
				position: absolute;
				// @include rem(left, 15px);
				top: 50%;
				margin-top: -0.6em;
				z-index: 1;
				@include rem(padding-left, 15px);
			}
		}

		.b-accordion-functional__button {
			font-size: inherit;
			@include rem(padding-left, 50px);
			padding-left: calc(0.9375rem + 2.1em);
			padding-left: calc(15px + 2.1em);

			@include hover {
				@include rem(padding-left, 50px);
				padding-left: calc(0.9375rem + 2.1em);
				padding-left: calc(15px + 2.1em);
			}

			&:active,
			&.is-expand {
				@include rem(padding-left, 50px);
				padding-left: calc(0.9375rem + 2.1em);
				padding-left: calc(15px + 2.1em);
			}
		}

		.b-accordion-functional__button__article {
			@include rem(padding, 18px 15px);
		}

		.b-accordion-functional__button__article--sections {
			padding: 0;
		}

		.article-close {
			display: none;
		}
	}
}

@if ($responsive) {
	@include breakpoint(xs) {
		.b-download-center-line {
			@include font-size(14px);

			&__content {
				@include rem(padding-right, 10px);
			}
		}

		.b-download-center-line--accordion {
			.b-accordion-functional__button {
				@include font-size(14px);
			}
		}

		.b-download-center-section {
			&__col {
				border-right: none;
			}

			&__col + &__col {
				padding-top: 0;
			}

			&__col-title {
				@include rem(margin-bottom, 10px);
			}

			&__title {
				@include font-size(14px);
			}
		}
	}
}
