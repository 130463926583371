.hystmodal__opened {
	position: fixed;
	right: 0;
	left: 0;
	overflow: hidden;
}

.hystmodal__shadow {
	position: fixed;
	border: none;
	display: block;
	width: 100%;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	overflow: hidden;
	pointer-events: none;
	z-index: 100;
	opacity: 0;
	transition: opacity 0.15s ease;
	background-color: black;
}

.hystmodal__shadow--show {
	pointer-events: auto;
	opacity: 0.6;
}

.hystmodal {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	overflow: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	opacity: 1;
	pointer-events: none;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	z-index: 101;
	visibility: hidden;
}

.hystmodal--active {
	opacity: 1;
}

.hystmodal--moved,
.hystmodal--active {
	pointer-events: auto;
	visibility: visible;
}

.hystmodal__wrap {
	flex-shrink: 0;
	flex-grow: 0;
	width: 100%;
	min-height: 100%;
	margin: auto;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;

	.browser-ie & {
		min-width: 100vw;
	}
}

.hystmodal__window {
	box-sizing: border-box;
	flex-shrink: 0;
	flex-grow: 0;
	background: #fff;
	width: 100%;
	max-width: 1200px;
	overflow: visible;
	transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
	transform: scale(0.9);
	opacity: 0;
	overflow: hidden;
}

.hystmodal--active .hystmodal__window {
	transform: scale(1);
	opacity: 1;
}

.hystmodal__close {
	padding: 0;
	font-size: 0;
	line-height: 0;
	outline: 0;
	background-color: transparent;
	z-index: 1;
}


$modal--width: 700px;

$modal--bg-color: $color-white;
$modal--close-color: $tools--color;
$modal--close-color-hover: $tools--color-hover;
$modal--close-color-active: $tools--color-active;

.modal {
	.hystmodal__window {
		max-width: $modal--width;
		border: 1px solid $project--main-color;
	}

	.hystmodal__styled {
		padding: 20px;
	}

	.hystmodal__close {
		position: absolute;
		top: 7px;
		right: 7px;
		// color: $modal--close-color;
		color: $color-ironside-gray;
		@include webfont-icon($webfont-icon--close_2);
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		&:before {
			font-size: 15px;
		}

		@include hover {
			color: $modal--close-color-hover;
		}

		&:active {
			color: $modal--close-color-active;
		}

		.zoom-in & {
			color: $modal--close-color;
		}
	}
}
