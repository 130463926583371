$checkbox-rect-border-color-disabled: $color-dark-gray;
$checkbox-size: 1.2em;

.b-checkbox {
	padding-left: $checkbox-size;
	cursor: pointer;
	position: relative;

	&__input {
		position: absolute;
		width: 1px;
		height: 1px;
		overflow: hidden;
		clip: rect(0 0 0 0);

	}

	&__rect {
		position: absolute;
		height: $checkbox-size;
		width: $checkbox-size;
		background-color: $checkbox--bg;
		// border: 1px solid $checkbox--border-color;
		border: 1px solid $color-blackcurrant;
		// margin-left: -1.1em;
		// border-radius: 0.2em;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		transition: background-color 1s ease, border 0.2s ease;

		&:before {
			content: none;
		}
	}

	/* Checked */

	&__input:checked + .b-checkbox__rect {
		border: 1px solid $checkbox--border-color-checked;
		background-color: $checkbox--bg-checked;
		@include webfont-icon($webfont-icon--checkmark);

		&:before {
			font-size: 0.75em;
			font-weight: normal;
			color: $checkbox--color-checked;
		}
	}

	/* Focus & hover */

	&__input:focus + .b-checkbox__rect {
		outline-width: 0;
	}

	&__input.focus-on:focus + .b-checkbox__rect {
		border-color: $checkbox--border-color-hover;
	}

	&__input + .b-checkbox__rect {
		@include hover {
			border-color: $checkbox--border-color-hover;
			background-color: $checkbox--bg-hover;
		}
	}

	/* Disabled + hover*/

	&__input:disabled + .b-checkbox__rect {
		border: 1px solid $checkbox-rect-border-color-disabled;

		&:before {
			color: $checkbox-rect-border-color-disabled;
		}
	}

	&__input:hover:disabled + .b-checkbox__rect {
		border: 1px solid $checkbox-rect-border-color-disabled;

		&:before {
			color: $checkbox-rect-border-color-disabled;
		}
	}

	&.is-partly-selected {
		.b-checkbox__rect {
			border-color: $checkbox--border-color-partly;
			background-color: $checkbox--bg-partly;
			@include webfont-icon($webfont-icon--checkmark);

			@include hover {
				border-color: $checkbox--border-color-hover;
			}

			&:before {
				font-size: 0.75em;
				font-weight: normal;
			}
		}
	}
}
