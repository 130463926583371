// tzar-core/src/gulp/utils/webfont-template.scss - файл-шаблон для генерации иконочного шрифта
// tzar-template/src/styles/webfont.scss - генерируемый файл шрифта, генерируется автоматически при сборке!

$icon_font_name:"webfont";

$webfont-icon--analyse: "\E001";
$webfont-icon--arrow-left: "\E002";
$webfont-icon--arrow-right: "\E003";
$webfont-icon--books: "\E004";
$webfont-icon--box-add: "\E005";
$webfont-icon--camera: "\E006";
$webfont-icon--checkmark: "\E007";
$webfont-icon--clipboard: "\E008";
$webfont-icon--clock: "\E009";
$webfont-icon--close: "\E00A";
$webfont-icon--close_2: "\E00B";
$webfont-icon--cookies-policy: "\E00C";
$webfont-icon--download: "\E00D";
$webfont-icon--enlarge: "\E00E";
$webfont-icon--external-link: "\E00F";
$webfont-icon--eye: "\E010";
$webfont-icon--facebook: "\E011";
$webfont-icon--file-empty: "\E012";
$webfont-icon--file-excel: "\E013";
$webfont-icon--file-pdf: "\E014";
$webfont-icon--file-text: "\E015";
$webfont-icon--file-word: "\E016";
$webfont-icon--file-zip: "\E017";
$webfont-icon--foursquare: "\E018";
$webfont-icon--google-plus: "\E019";
$webfont-icon--google-plus_2: "\E01A";
$webfont-icon--histogram: "\E01B";
$webfont-icon--home: "\E01C";
$webfont-icon--i-play: "\E01D";
$webfont-icon--i-see-down: "\E01E";
$webfont-icon--i-zoom: "\E01F";
$webfont-icon--instagram: "\E020";
$webfont-icon--lang-en: "\E021";
$webfont-icon--lang-ru: "\E022";
$webfont-icon--library: "\E023";
$webfont-icon--line: "\E024";
$webfont-icon--linkedin: "\E025";
$webfont-icon--location: "\E026";
$webfont-icon--mail: "\E027";
$webfont-icon--menu: "\E028";
$webfont-icon--microphone: "\E029";
$webfont-icon--next: "\E02A";
$webfont-icon--pdf-double-page: "\E02B";
$webfont-icon--pdf-page: "\E02C";
$webfont-icon--pdf: "\E02D";
$webfont-icon--phone: "\E02E";
$webfont-icon--prev: "\E02F";
$webfont-icon--printer: "\E030";
$webfont-icon--reload: "\E031";
$webfont-icon--reload_2: "\E032";
$webfont-icon--report: "\E033";
$webfont-icon--search: "\E034";
$webfont-icon--share: "\E035";
$webfont-icon--tools: "\E036";
$webfont-icon--tooltip: "\E037";
$webfont-icon--tree: "\E038";
$webfont-icon--twitter: "\E039";
$webfont-icon--up: "\E03A";
$webfont-icon--users: "\E03B";
$webfont-icon--vk: "\E03C";
$webfont-icon--vk_2: "\E03D";
$webfont-icon--youtube: "\E03E";
$webfont-icon--youtube_2: "\E03F";
$webfont-icon--zoom-in: "\E040";

@mixin webfont-icon($icon-code, $after-or-before: 'before') {
	&:#{$after-or-before} {
		content: #{'"' + $icon-code + '"'};
		display: inline-block;
		font-family: $icon_font_name;
		font-style: normal;
		line-height: 1;
		color: currentColor;
		-webkit-font-smoothing: antialiased;
		speak: none;
	}
}

@font-face {
	font-family: $icon_font_name;
	src: url('/fonts/webfont/webfont.woff') format('woff'),
	url('/fonts/webfont/webfont.woff2') format('woff2'),
	url('/fonts/webfont/webfont.svg#webfont') format('svg');
}

.webfont-icon {
	font-family: $icon_font_name;
	display: inline-block;
	vertical-align: middle;
	font-style: normal;
	speak: none;
	color: currentColor;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}

.webfont-icon--analyse:before {
	content: '\E001';
}

.webfont-icon--arrow-left:before {
	content: '\E002';
}

.webfont-icon--arrow-right:before {
	content: '\E003';
}

.webfont-icon--books:before {
	content: '\E004';
}

.webfont-icon--box-add:before {
	content: '\E005';
}

.webfont-icon--camera:before {
	content: '\E006';
}

.webfont-icon--checkmark:before {
	content: '\E007';
}

.webfont-icon--clipboard:before {
	content: '\E008';
}

.webfont-icon--clock:before {
	content: '\E009';
}

.webfont-icon--close:before {
	content: '\E00A';
}

.webfont-icon--close_2:before {
	content: '\E00B';
}

.webfont-icon--cookies-policy:before {
	content: '\E00C';
}

.webfont-icon--download:before {
	content: '\E00D';
}

.webfont-icon--enlarge:before {
	content: '\E00E';
}

.webfont-icon--external-link:before {
	content: '\E00F';
}

.webfont-icon--eye:before {
	content: '\E010';
}

.webfont-icon--facebook:before {
	content: '\E011';
}

.webfont-icon--file-empty:before {
	content: '\E012';
}

.webfont-icon--file-excel:before {
	content: '\E013';
}

.webfont-icon--file-pdf:before {
	content: '\E014';
}

.webfont-icon--file-text:before {
	content: '\E015';
}

.webfont-icon--file-word:before {
	content: '\E016';
}

.webfont-icon--file-zip:before {
	content: '\E017';
}

.webfont-icon--foursquare:before {
	content: '\E018';
}

.webfont-icon--google-plus:before {
	content: '\E019';
}

.webfont-icon--google-plus_2:before {
	content: '\E01A';
}

.webfont-icon--histogram:before {
	content: '\E01B';
}

.webfont-icon--home:before {
	content: '\E01C';
}

.webfont-icon--i-play:before {
	content: '\E01D';
}

.webfont-icon--i-see-down:before {
	content: '\E01E';
}

.webfont-icon--i-zoom:before {
	content: '\E01F';
}

.webfont-icon--instagram:before {
	content: '\E020';
}

.webfont-icon--lang-en:before {
	content: '\E021';
}

.webfont-icon--lang-ru:before {
	content: '\E022';
}

.webfont-icon--library:before {
	content: '\E023';
}

.webfont-icon--line:before {
	content: '\E024';
}

.webfont-icon--linkedin:before {
	content: '\E025';
}

.webfont-icon--location:before {
	content: '\E026';
}

.webfont-icon--mail:before {
	content: '\E027';
}

.webfont-icon--menu:before {
	content: '\E028';
}

.webfont-icon--microphone:before {
	content: '\E029';
}

.webfont-icon--next:before {
	content: '\E02A';
}

.webfont-icon--pdf-double-page:before {
	content: '\E02B';
}

.webfont-icon--pdf-page:before {
	content: '\E02C';
}

.webfont-icon--pdf:before {
	content: '\E02D';
}

.webfont-icon--phone:before {
	content: '\E02E';
}

.webfont-icon--prev:before {
	content: '\E02F';
}

.webfont-icon--printer:before {
	content: '\E030';
}

.webfont-icon--reload:before {
	content: '\E031';
}

.webfont-icon--reload_2:before {
	content: '\E032';
}

.webfont-icon--report:before {
	content: '\E033';
}

.webfont-icon--search:before {
	content: '\E034';
}

.webfont-icon--share:before {
	content: '\E035';
}

.webfont-icon--tools:before {
	content: '\E036';
}

.webfont-icon--tooltip:before {
	content: '\E037';
}

.webfont-icon--tree:before {
	content: '\E038';
}

.webfont-icon--twitter:before {
	content: '\E039';
}

.webfont-icon--up:before {
	content: '\E03A';
}

.webfont-icon--users:before {
	content: '\E03B';
}

.webfont-icon--vk:before {
	content: '\E03C';
}

.webfont-icon--vk_2:before {
	content: '\E03D';
}

.webfont-icon--youtube:before {
	content: '\E03E';
}

.webfont-icon--youtube_2:before {
	content: '\E03F';
}

.webfont-icon--zoom-in:before {
	content: '\E040';
}

