$header-bg: transparent;
$body-bg: $color-blackcurrant;
$footer-bg: $color-blackcurrant;
$layout--page-padding-top: 54px;

@if ($contrast) {
	$header-bg: #656565;
	$body-bg: #656565;
	$footer-bg: #656565;
}

body {
	background-color: $body-bg;
	overflow-x: hidden;
}

.l-layout-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	margin: 0 auto;

	&__header {
		flex: none;

		&:before {
			content: '';
			position: absolute;
			width: 100vw;
			height: 100%;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			background-color: $header-bg;
			z-index: -1;
		}
	}

	&__footer {
		background-color: $footer-bg;
		flex: none;
	}
}

.l-page {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-shrink: 0;
	min-height: 1px;

	&__sidebar {
		flex-grow: 0;
		flex-shrink: 0;
	}

	&__sidebar--left {
		flex-basis: $left-col-width;
		width: $left-col-width;
		min-width: $left-col-width;
	}

	&__sidebar--right {
		flex-basis: $right-col-width;
		width: $right-col-width;
		min-width: $right-col-width;
	}

	&__sidebar-section {
		@include rem(margin-bottom, 10px);
	}

	&__sidebar-section--adaptive-menu {
		display: none;
	}

	&__content {
		padding-bottom: 3em;
		padding-left: $grid-gutter-width;
		padding-right: $grid-gutter-width;
		flex-shrink: 1;
		flex-grow: 1;
		width: 100%;
	}

	&__content--with-left {
		padding-left: 0;
		width: $left-col-width + $center-col-width;
	}

	&__content--with-right {
		padding-right: 0;
		width: $right-col-width + $center-col-width;
	}

	&__content--wide {
		padding-right: 0;
		padding-left: 0;
		width: 100%;
	}

	&__nav {
		display: flex;
		align-items: center;
	}

	&__nav-breadcrumbs {
		flex-grow: 1;
	}

	&__logo {
		order: -1;
		margin: 0 0 43px !important;
		width: 160px;
	}

	&__header {
		order: -1;
	}

	&__accordion {
		margin-top: 95px;
	}
}

.content-area {
	min-height: 1px;
	display: flex;
	flex-direction: column;
}

.l-content--with-left {
	margin-left: -($left-col-width + 15px);
}

.l-content--with-right {
	margin-right: -($right-col-width + 15px);
}

.aside {
	position: absolute;
	margin-top: 0;
}

.aside--right {
	width: $right-col-width;
	right: -$right-col-width;
	padding-left: $grid-gutter-width;
}

.aside--left {
	width: $left-col-width;
	left: -$left-col-width;
	padding-right: $grid-gutter-width;
}

.aside + .aside {
	margin-top: 150px;

	@include breakpoint(sm) {
		margin-top: 0px;
	}
}

@if ($responsive) {
	.l-layout-wrapper__page {
		padding-left: $grid-gutter-width;
		padding-right: $grid-gutter-width;
		max-width: 1371px + $grid-gutter-width * 2;
		min-width: 900px;
		margin: auto;
	}

	.l-page__content {
		padding-top: 76px;
	}

	.l-layout-wrapper__header {
		padding-left: 84px;
		padding-right: 84px;
	}

	@include breakpoint(lg) {
		.l-page__logo {
			width: 128px;
		}

		.l-layout-wrapper__page {
			max-width: 900px + $grid-gutter-width * 2;
		}

		.l-page__content {
			padding: 54px 0 95px;
		}
	}

	@include breakpoint(md) {
		.l-layout-wrapper__header {
			padding-left: 50px;
			padding-right: 50px;
		}

		.l-layout-wrapper__page {
			min-width: auto;
		}
	}

	@include breakpoint(sm) {
		.l-page__content {
			padding: $grid-gutter-width 0 18px;
		}

		.l-layout-wrapper__page {
			padding-left: $grid-gutter-width * 2;
			padding-right: $grid-gutter-width * 2;
		}

		.l-layout-wrapper__header {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}

		.l-page__sidebar-section--menu {
			display: none;
		}

		.l-page__sidebar-section--adaptive-menu {
			display: block;
		}

		.l-page__accordion {
			margin-top: 54px;
		}

		.aside {
			position: static;
			width: 100%;
			padding-left: 0;
			padding-right: 0;
		}

		.aside + .aside {
			margin-top: 0px;
		}
	}

	@include breakpoint(xs) {
		.l-layout-wrapper__page {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}
	}
}
