@mixin hover {
	.browser-ie & {
		&:hover,
		&:visited:hover {
			@content;
		}
	}

	@media (hover:hover) {
		&:hover,
		&:visited:hover {
			@content;
		}
	}
}
