.b-search {
	&.b-search--result {
		position: relative;
		width: 100%;
		display: flex;

		.b-search__input {
			width: 100%;
			height: 100%;
			border: none;
			border-bottom: 1px solid $base-text-color;
			opacity: 0.8;
			transition: border-color 0.2s ease;
			padding-right: 40px;
			background: none;
			color: $base-text-color;

			&:focus {
				opacity: 1;
				border-color: $color-deep-sky-blue;
			}
		}

		.b-search__wrapper-input {
			display: block;
			width: 100%;
			margin-right: 42px;
		}

		.b-search__button {
			display: block;
			height: 100%;
			z-index: 1;
			border-radius: 20px;
			border-width: 1px;
			@include rem(width, 188px);
		}
	}
}
