.b-indicator {
	display: block;
	font-weight: normal;
	margin-bottom: 40px;

	&__main {
		@include rem(margin-bottom, 8px);

		.body--main-page & {
			@include rem(margin-bottom, 12px);
		}
	}

	&__value {
		display: inline-block;
		@include font(300, 'BanderaPro');
		@include font-size(55px);
		line-height: 90%;

		.body--main-page & {
			@include rem(margin-bottom, 5px);
		}
	}

	&__value--big {
		@include font-size(120px);
	}

	&__value--small {
		@include font-size(65px);
	}

	&__value--arrow {
		@include webfont-icon($webfont-icon--up);

		&:before {
			vertical-align: top;
			font-size: 0.5em;
		}
	}

	&__value--arrow-up {
	}

	&__value--arrow-down {
		&:before {
			transform: rotate(180deg);
		}
	}

	&__value--print {
		display: none !important;
	}

	&__value--blue {
		color: $color-deep-sky-blue;
	}

	&__value--pink {
		color: $color-medium-violet-red;
	}

	&__unit {
		display: block;
		@include font(500, 'BanderaPro');
		@include font-size(18px);
		line-height: 1;
	}

	&__unit--inline {
		display: inline-block;
		@include font(500, 'BanderaPro');
		@include font-size(22px);
		line-height: 1;
	}

	&__unit--big {
		@include font-size(39px);
	}

	&__unit--superbig {
		@include font-size(120px);
	}

	&__unit--small {
		@include font-size(18px);
		line-height: 110%;
	}

	&__unit--blue {
		color: $color-deep-sky-blue;
	}

	&__unit--pink {
		color: $color-medium-violet-red;
	}

	&__text {
		display: inline-block;
		@include font(300);
		@include font-size(18px);
		line-height: 1.2;
	}

	&__text--big {
		@include font-size(22px);
	}

	&__text--small {
		@include font-size(19px);
		margin-top: -4px;
	}

	&__value,
	&__unit,
	&__text {
		& + & {
			@extend %collapse--top;
		}
	}

	&--figure {
		position: relative;
		@include rem(padding-top, 45px);
		@include rem(padding-left, 160px);
		@include rem(margin-top, 54px);
		@include rem(margin-bottom, 40px);
		z-index: 1;
		min-height: 350px;

		&:before {
			content: '';
			display: block;
			border: 1px solid $color-white;
			opacity: 0.3;
			transform: rotate(-45deg);
			position: absolute;
			top: 25px;
    		left: 5%;
			z-index: -1;
			@include rem(width, 240px);
			@include rem(height, 240px);
		}
	}
}

@if ($responsive) {
	@include breakpoint(lg) {
		.b-indicator {
			&--figure {
				@include rem(padding-left, 110px);
				@include rem(padding-top, 24px);
				min-height: 250px;

				&:before {
					@include rem(width, 197px);
					@include rem(height, 197px);
				}
			}

			&__value--big {
				@include font-size(95px);
			}

			&__unit--superbig {
				@include font-size(95px);
			}

			&__unit--big {
				@include font-size(30px);
			}

			&__text--big {
				@include font-size(30px);
			}

			&__value--small {
				@include font-size(50px);
			}

			&__unit--small {
				@include font-size(12px);
				line-height: 110%;
			}

			&__text--small {
				@include font-size(12px);
				margin-top: -4px;
			}
		}
	}

	@include breakpoint(md) {
		.b-indicator {
			&__value--small {
				@include font-size(61px);
			}

			&__unit--small {
				@include font-size(15px);
			}

			&__text--small {
				@include font-size(15px);
			}
		}
	}

	@include breakpoint(sm) {
		.b-indicator {
			&--figure {
				height: auto;
				padding-top: 0;
				margin-bottom: 54px;

				&:before {
					left: 5%;
					top: auto;
				}
			}

			&__value--big {
				@include font-size(80px);
			}
			&__unit--superbig {
				@include font-size(80px);
			}

			&__text--big {
				@include font-size(23px);
			}
		}
	}

	@include breakpoint(xs) {
		.b-indicator {
			&--figure {
				padding-left: 48px;

				&:before {
					left: 15%;
				}
			}
		}
	}
}
