$buttons-panel--trigger-icon-size: 22px;
$buttons-panel--trigger-text-font-size: 15px;
$buttons-panel--trigger-color: $color-white;
$buttons-panel--trigger-color-hover: $color-deep-sky-blue;
$buttons-panel--trigger-color-active: $color-dodger-blue;
$buttons-panel--list-bg-color: linear-gradient(0deg, rgba(0, 185, 242, 0.05), rgba(0, 185, 242, 0.05)), rgba(22, 17, 30, 0.95);
$buttons-panel--link-icon-font-size: 18px;
$buttons-panel--link-font-size: 13px;
$buttons-panel--link-color: $base-text-color;
$buttons-panel--link-color-hover: $color-deep-sky-blue;
$buttons-panel--link-color-active: $color-dodger-blue;
$buttons-panel--link-bg-color: transparent;
$buttons-panel--link-bg-color-hover: transparent;
$buttons-panel--link-bg-color-active: transparent;
$buttons-panel--counter-width: 12px;
$buttons-panel--counter-height: 12px;
$buttons-panel--counter-color: $buttons-panel--link-color;
$buttons-panel--counter-color-hover: $buttons-panel--link-color-hover;
$buttons-panel--counter-color-active: $buttons-panel--link-color-active;
$buttons-panel--counter-bg-color: $buttons-panel--link-bg-color;
$buttons-panel--counter-bg-color-hover: $buttons-panel--link-bg-color-hover;
$buttons-panel--counter-bg-color-active: $buttons-panel--link-bg-color-active;
$buttons-panel--counter-border-color: $buttons-panel--counter-color;
$buttons-panel--counter-border-color-hover: $buttons-panel--counter-color-hover;
$buttons-panel--counter-border-color-active: $buttons-panel--counter-color-active;
$buttons-panel--sublist-bg-color: transparent;
$buttons-panel--sublist-icon-font-size: 8px;
$buttons-panel--sublist-link-width: 21px;
$buttons-panel--sublist-link-height: 21px;
$buttons-panel--sublist-link-icon-color: $color-white;
$buttons-panel--sublist-link-icon-color-hover: $color-deep-sky-blue;
$buttons-panel--sublist-link-icon-color-active: $color-dodger-blue;
$buttons-panel--sublist-icon-bg-color: transparent;
$buttons-panel--sublist-icon-bg-color-hover: transparent;
$buttons-panel--sublist-icon-bg-color-active: transparent;
$buttons-panel--compare-link-font-size: 14px;
$buttons-panel--tooltips-color: $color-white;
$buttons-panel--tooltips-bg-color: transparent;
$buttons-panel--title-font-size: 17px;
$buttons-panel--title-color: $base-text-color;

@if ($contrast) {
	$buttons-panel--trigger-color: $color-white;
	$buttons-panel--trigger-color-hover: $color-deep-sky-blue;
	$buttons-panel--trigger-color-active: $color-dodger-blue;
	$buttons-panel--list-bg-color: linear-gradient(0deg, rgba(0, 185, 242, 0.05), rgba(0, 185, 242, 0.05)), rgba(22, 17, 30, 0.95);
	$buttons-panel--link-color: $base-text-color;
	$buttons-panel--link-color-hover: $color-deep-sky-blue;
	$buttons-panel--link-color-active: $color-dodger-blue;
	$buttons-panel--link-bg-color: transparent;
	$buttons-panel--link-bg-color-hover: transparent;
	$buttons-panel--link-bg-color-active: transparent;
	$buttons-panel--counter-color: $buttons-panel--link-color;
	$buttons-panel--counter-color-hover: $buttons-panel--link-color-hover;
	$buttons-panel--counter-color-active: $buttons-panel--link-color-active;
	$buttons-panel--counter-bg-color: $buttons-panel--link-bg-color;
	$buttons-panel--counter-bg-color-hover: $buttons-panel--link-bg-color-hover;
	$buttons-panel--counter-bg-color-active: $buttons-panel--link-bg-color-active;
	$buttons-panel--counter-border-color: $buttons-panel--counter-color;
	$buttons-panel--counter-border-color-hover: $buttons-panel--counter-color-hover;
	$buttons-panel--counter-border-color-active: $buttons-panel--counter-color-active;
	$buttons-panel--sublist-bg: transparent;
	$buttons-panel--sublist-bg-color: transparent;
	$buttons-panel--sublist-link-icon-color: $color-white;
	$buttons-panel--sublist-link-icon-color-hover: $color-deep-sky-blue;
	$buttons-panel--sublist-link-icon-color-active: $color-dodger-blue;
	$buttons-panel--sublist-icon-bg-color: transparent;
	$buttons-panel--sublist-icon-bg-color-hover: transparent;
	$buttons-panel--sublist-icon-bg-color-active: transparent;
	$buttons-panel--tooltips-bg-color: transparent;
	$buttons-panel--tooltips-color: $color-white;
	$buttons-panel--title-color: $base-text-color;
}

%counter {
	position: absolute;
	display: block;
	text-align: center;
	color: $buttons-panel--counter-color;
	background-color: $buttons-panel--counter-bg-color;
	border-width: 1px;
	border-style: solid;
	border-color: $buttons-panel--counter-border-color;
	border-radius: 50%;
	@include font-size(9px);
	@include font(600);
	@include rem(width, $buttons-panel--counter-width);
	@include rem(height, $buttons-panel--counter-height);
	@include rem(top, 12px);
	@include rem(left, 25px);
	@extend .user-select--no;
}

.b-buttons-panel__link {
	position: relative;
	display: block;
	text-decoration: none;
	line-height: 1.2;
	background-color: $buttons-panel--link-bg-color;
	@include font-size($buttons-panel--link-font-size);
	color: $buttons-panel--link-color;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include font-size($buttons-panel--link-icon-font-size);
	}

	&:visited {
		color: $buttons-panel--link-color;
	}

	&:hover,
	&:visited:hover {
		background-color: $buttons-panel--link-bg-color-hover;
		color: $buttons-panel--link-color-hover;
	}

	&:active,
	&.is-expand,
	&:visited:active {
		background-color: $buttons-panel--link-bg-color-active;
		color: $buttons-panel--link-color-active;
	}

	&.is-visible {
		display: block;
	}

	//Мой отчет
	&--myreport {
		@include webfont-icon($webfont-icon--report);

		.b-buttons-panel__counter {
			@extend %counter;
		}

		&:hover {
			.b-buttons-panel__counter {
				color: $buttons-panel--counter-color-hover;
				background-color: $buttons-panel--counter-bg-color-hover;
				border-color: $buttons-panel--counter-border-color-hover;
			}
		}

		&:active {
			.b-buttons-panel__counter {
				color: $buttons-panel--counter-color-active;
				background-color: $buttons-panel--counter-bg-color-active;
				border-color: $buttons-panel--counter-border-color-active;
			}
		}
	}

	&--add,
	&--del {
		display: none;

		&:after {
			@extend %counter;
		}

		&:hover {
			&:after {
				color: $buttons-panel--counter-color-hover;
				background-color: $buttons-panel--counter-bg-color-hover;
				border-color: $buttons-panel--counter-border-color-hover;
			}
		}

		&:active {
			&:after {
				color: $buttons-panel--counter-color-active;
				background-color: $buttons-panel--counter-bg-color-active;
				border-color: $buttons-panel--counter-border-color-active;
			}
		}
	}

	//Добавить в мой отчет
	&--add {
		@include webfont-icon($webfont-icon--report);

		&:after {
			content: '+';
		}
	}

	//Удалить из моего отчета
	&--del {
		@include webfont-icon($webfont-icon--report);

		&:after {
			content: '-';
		}
	}

	//Печать страницы
	&--print {
		@include webfont-icon($webfont-icon--printer);
	}

	//Скачать PDF
	&--pdf {
		@include webfont-icon($webfont-icon--file-pdf);
	}

	//Поделится в соц. сетях или по e-mail
	&--share {
		@include webfont-icon($webfont-icon--share);
	}

	//История просмотренных страниц
	&--history {
		@include webfont-icon($webfont-icon--clock);
	}

	//Обратня связь
	&--feedback {
		@include webfont-icon($webfont-icon--mail);

		&:before {
			@include font-size(12px);
		}
	}

	//Центр загрузки
	&--download {
		@include webfont-icon($webfont-icon--download);
	}

	//Интерактивный анализ
	&--analysis {
		@include webfont-icon($webfont-icon--analyse);
	}

	//Сравнить с прошлым отчетом
	&--compare {
		@include webfont-icon($webfont-icon--books);
	}

	//Смотреть предыдущие отчеты
	&--see {
		@include webfont-icon($webfont-icon--eye);
	}

	//предыдущие отчеты
	&--previous-reports {
		@include webfont-icon($webfont-icon--books);
	}

	//Карта сайта
	&--site-map {
		@include webfont-icon($webfont-icon--tree);
	}

	//глосарий
	&--glossary {
		@include webfont-icon($webfont-icon--library);
	}

	//архив отчетов
	&--archive-reports {
		@include webfont-icon($webfont-icon--box-add);
	}

	//disclaimer
	&--disclaimer {
		@include webfont-icon($webfont-icon--file-text);
	}

	//Поиск
	&--search {
		@include webfont-icon($webfont-icon--search);
	}
}

//Стили для sublist (share, social, compare, etc.)
.b-buttons-panel__sublist {
	display: none;
	background-color: $buttons-panel--sublist-bg-color;
}

.b-buttons-panel__sublist-inner {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.b-buttons-panel__sublist-link {
	position: relative;
	display: block;
	text-decoration: none;
	color: $buttons-panel--sublist-link-icon-color;
	background-color: $buttons-panel--sublist-icon-bg-color;
	@include rem(width, $buttons-panel--sublist-link-width);
	@include rem(height, $buttons-panel--sublist-link-height);
	border: 1px solid $buttons-panel--sublist-link-icon-color;
	border-radius: 50%;
	transition: color 0.2s ease, border-color 0.2s ease;

	&:before {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		@include font-size($buttons-panel--sublist-icon-font-size);
	}

	&:hover,
	&:visited:hover {
		color: $buttons-panel--sublist-link-icon-color-hover;
		border-color: $buttons-panel--sublist-link-icon-color-hover;
		background-color: $buttons-panel--sublist-icon-bg-color-hover;
	}

	&:active,
	&:visited:active {
		color: $buttons-panel--sublist-link-icon-color-active;
		border-color: $buttons-panel--sublist-link-icon-color-active;
		background-color: $buttons-panel--sublist-icon-bg-color-active;
	}

	&--vkontakte {
		@include webfont-icon($webfont-icon--vk);

		&:before {
			@include font-size(10px);
		}
	}

	&--linkedin {
		@include webfont-icon($webfont-icon--linkedin);
	}

	&--twitter {
		@include webfont-icon($webfont-icon--twitter);
	}

	&--facebook {
		@include webfont-icon($webfont-icon--facebook);

		&:before {
			@include font-size(11px);
		}
	}

	&--youtube {
		@include webfont-icon($webfont-icon--youtube);
	}

	&--googleplus {
		@include webfont-icon($webfont-icon--google-plus);

		&:before {
			@include font-size(13px);
		}
	}

	&--email {
		@include webfont-icon($webfont-icon--mail);

		&:before {
			@include font-size(7px);
		}
	}
}

.b-buttons-panel__sublist-link--compare {
	white-space: nowrap;
	float: none;
	width: auto;
	height: auto;
	@include rem(padding, 5px 10px);
	@include font-size($buttons-panel--compare-link-font-size);
}

.tooltipster-sidetip.tooltipster-buttons-panel {
	.tooltipster-box {
		border: none;
		background: $buttons-panel--tooltips-bg-color;
		color: $buttons-panel--tooltips-color;
		@include font-size(15px);
		@include font(400);
		@include rem(border-radius, 5px);
	}

	.tooltipster-content {
		color: $buttons-panel--tooltips-color;
	}

	&.tooltipster-bottom .tooltipster-arrow-background {
		border-bottom-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-left .tooltipster-arrow-background {
		border-left-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-right .tooltipster-arrow-background {
		border-right-color: $buttons-panel--tooltips-bg-color;
	}

	&.tooltipster-top .tooltipster-arrow-background {
		border-top-color: $buttons-panel--tooltips-bg-color;
	}

	.tooltipster-arrow-border {
		border: none;
	}

	&.tooltipster-bottom .tooltipster-arrow-uncropped {
		top: -11px;
	}

	&.tooltipster-right .tooltipster-arrow-uncropped {
		left: -11px;
	}
}
