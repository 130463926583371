// $zoom--overlay-bg-color: #212121;
$zoom--box-bg-color: $color-blackcurrant;

$zoom--btn-color: $tools--color;
$zoom--btn-color-hover: $tools--color-hover;
$zoom--btn-color-active: $tools--color-active;

$zoom--close-btn-color: $zoom--btn-color;
$zoom--close-btn-color-hover: $zoom--btn-color-hover;
$zoom--close-btn-color-active: $zoom--btn-color-active;

.button--zoom {
	display: inline-block;
	background-color: transparent;
	// @include webfont-icon($webfont-icon--zoom-in);
	@include webfont-icon($webfont-icon--i-zoom);
	font-size: 0;
	color: $zoom--btn-color;
	@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */
	margin: 1px 6px;
	padding: 0;

	&:before {
		font-size: 20px;
	}

	@include hover {
		color: $zoom--btn-color-hover;
	}

	&:active {
		color: $zoom--btn-color-active;
	}
}

.zoom-in {
	.hystmodal__styled {
		background: $zoom--box-bg-color;
		padding: 20px;
	}

	.hystmodal__close {
		position: absolute;
		display: block;
		top: 20px;
		right: 20px;
		background: none;
		@include webfont-icon($webfont-icon--close_2);
		transition: background-color 0.2s ease, transform 0.2s ease;
		color: $zoom--close-btn-color;
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		&:before {
			font-size: 15px;
		}

		@include hover {
			color: $zoom--close-btn-color-hover;
		}

		&:active {
			color: $zoom--close-btn-color-active;
		}
	}

	figcaption {
		font-size: 20px;
		padding-right: 20px;
	}

	.caption__units,
	.b-caption__units {
		font-size: 20px;
	}

	.graphic,
	.b-table {
		padding-top: 26px;
		margin: 0;

		figcaption {
			margin-top: -26px;
		}
	}

	.graphic {
		.figure-buttons {
			display: none;
		}
	}

	.b-table {
		figcaption {
			padding-right: 70px;
		}

		.figure-buttons {
			position: absolute;
			right: 55px;
			top: 18px;
			margin-top: 0;
		}
	}

	img {
		max-width: 100%;
	}
}
