// Цвет фона фиксированной шапки
$table--fixed-head-bg: $color-blackcurrant;
// Шапка
$table--header-color: $color-white;
$table--header-bg: $color-blackcurrant;
// Подзаголовок
$table--subhead-color: $color-white;
// Выделенная ячейка
$table--strong-cell-color: $base-text-color;
$table--strong-cell-bg: $color-blackcurrant;
$table--strong-cell-border: $color-white;
// Строка итогов
$table--summary-bg: $color-blackcurrant;
$table--summary-color: $color-deep-sky-blue;
$table--summary-hover: $color-mountain-mist;
$table--summary-active-js: $table--summary-hover;
// Фон строки по наведению
$table-row-bg-hover: rgba(0, 185, 242, 0.05);
$table-row-bg-strong-hover: rgba(0, 185, 242, 0.05);
// Выделенная строка
$table--strong-row-color: $color-white;
$table--strong-row-bg: $table--strong-cell-bg;
$table--strong-row-bg-hover: $color-blackcurrant;
$table--strong-row-bg-js: $color-blackcurrant;
// Выделенная строка (при клике)
$table--selected-row-bg: rgba(0, 185, 242, 0.12);
$table--selected-row-bg-strong: rgba(0, 185, 242, 0.12);

@if ($contrast) {
	$table--header-color: $color-white;
	$table--header-bg: $color-mine-shaft;
	$table--strong-cell-color: $color-taupe-gray;
	$table--strong-cell-bg: $color-eallery;
	$table--strong-cell-border: $color-davys-grey;
	$table--subhead-color: $color-error;
	$table--summary-bg: $color-dark-jungle;
	$table--summary-color: $color-white;
	$table--fixed-head-bg: $color-tapa-gray;
}

table {
    border-collapse: separate;
    border-spacing: 4px 0;
    margin-bottom: 30px;

	td,
	th {
        padding: 0;
	}

	.a_l {
		text-align: left !important;
	}

	.a_c {
		text-align: center !important;
	}

	.a_r {
		text-align: right !important;
	}

	.a_t {
		vertical-align: top !important;
	}

	.a_m {
		vertical-align: middle !important;
	}

	.a_b {
		vertical-align: bottom !important;
	}
}

// Стили для финансовой таблицы (стандартной)
.b-table {
	figcaption {
		@include font-size(20px);
		font-weight: bold;
		color: $base-text-color;
		margin-bottom: $grid-gutter-width;

		.caption__units,
		.b-caption__units {
			font-weight: normal;
		}
	}

	table {
		min-width: 100%;

		thead {
			//Ячейки в заголовках
			td,
			th {
				color: $table--header-color;
				background-color: $table--header-bg;
                text-align: right;
                font-family: "BanderaPro";
                @include rem(font-size, 17px);
                border-bottom: 1px solid $color-deep-sky-blue;

				&[colspan] {
					text-align: center;
				}
			}

			tr:first-child {
				th:first-child,
				td:first-child {
					text-align: left;
				}
			}
		}

		tbody {
			tr {
				&:hover,
				&.is-hover {
					background-color: $table-row-bg-hover;

					.b-cell--strong {
						background-color: $table-row-bg-strong-hover;
					}
				}
			}
		}

		//Обычные ячейки (не в заголовках)
		td,
		th {
			position: relative;
			text-align: right;
            border-bottom-style: solid;
            border-bottom-color: rgba(255, 255, 255, 0.3);
			@include rem(border-bottom-width, 1px);
            @include rem(padding, 8px);
            @include rem(font-size, 15px);
			@include rem(line-height, 29px);
			transition: background-color 0.2s linear;
			background-clip: padding-box;

			&:first-child {
				text-align: left;
			}

			@include breakpoint(sm) {
				color: black;
				border-bottom-color: black;
			}

		}
	}

	//Подзаголовок
	.b-subhead {
		font-weight: bold;
		text-transform: uppercase;
		color: $table--subhead-color;
	}

	.b-subhead--level-1 td:first-child {
		padding-left: 0;
	}

	.b-subhead--level-2 td:first-child {
		@include rem(padding-left, 16px);
	}

	.b-subhead--level-3 td:first-child {
		@include rem(padding-left, 24px);
	}

	//Выделеная ячейка
	.b-cell--strong {
		color: $table--strong-cell-color;
		background-color: $table--strong-cell-bg;
	}

	.b-cell--strong + .b-cell--strong {
		&:before {
			@include pseudo(1px, 100%, '');
			background-color: $table--strong-cell-border;
			top: 0;
			left: 0;
		}
	}

	.b-cell--level-1 {
		padding-left: 0;
	}

	.b-cell--level-2 {
		@include rem(padding-left, 25px);
	}

	.b-cell--level-3 {
		@include rem(padding-left, 50px);
	}

	.b-cell--regular {
		font-weight: 400 !important;
	}

	.b-cell--bold {
		font-weight: 700 !important;
	}

	//Выделеная строка
	.b-row--strong {
		color: $table--strong-row-color;
		background-color: $table--strong-row-bg;

		.is-active,
		.is-hover {
			background-color: $table--strong-row-bg-js;
		}

		&:hover {
			background-color: $table--strong-row-bg-hover;
		}
	}

	//Выбранная строка(по клику)
	.b-selected-row {

		td {
            background-color: $table--selected-row-bg !important;
            border-bottom-color: $color-white;
		}

		.b-cell--strong {
			background-color: $table--selected-row-bg-strong !important;
            border-bottom-color: $color-white;
		}
	}

	//Итоговая строка
	.b-summary {
		font-weight: bold;
		color: $table--summary-color;
        background-color: $table--summary-bg;

        td {
            border-bottom-color: $color-deep-sky-blue;
        }

		&:hover {
			background-color: $table--summary-hover;
		}

		.is-active,
		.is-hover {
			background-color: $table--summary-active-js;
		}
	}

	.b-summary--level-1 td:first-child {
		padding-left: 0;
	}

	.b-summary--level-2 td:first-child {
		@include rem(padding-left, 25px);
	}

	.b-summary--level-3 td:first-child {
		@include rem(padding-left, 50px);
	}

	.b-fixed-head {
		overflow: hidden;
		z-index: 10;
		visibility: hidden;
		position: fixed;
		top: 0;
		left: -99999px;
		margin-top: 0;
		background-color: $table--fixed-head-bg;
	}

	// Цвет всего столбца, по наведению на ячейку
	.is-hover {
		background-color: $color-eallery;
	}

	// Цвет всего столбца, по клику на заголовок столбца
	.is-active {
		background-color: $color-eallery;
	}
}

.b-table--simple {
	table {
		thead {
			th,
			td {
				text-align: left;
			}
		}

		td,
		th {
			text-align: left;
		}
	}
}

// Классы масштабирования
@for $i from 1 through 9 {
	.b-table--scale-#{$i},
	.b-table--auto-scale-#{$i} {
		table {
			td,
			th {
				font-size: 1em - $i / 10 !important;
				padding-left: 1em - $i / 10 !important;
				padding-right: 1em - $i / 10 !important;
			}
		}
	}
}

// Кнопка, скрывающая/раскрывающая таблицу
.b-table__toggle-visible-rows-trigger {
	background: $color-ironside-gray;
	display: inline-block;
	color: $color-white;
	@include rem(padding, 3px 10px);
	text-decoration: none;
}

// Стили для таблицы с горизонтальным скроллом
.b-table {
	&__content {
		overflow: auto;
	}

	&__content-wrapper {
		position: relative;
	}
}

.b-table__content,
.b-table {
	::-webkit-scrollbar {
		height: 14px;
	}

	::-webkit-scrollbar-track {
		background: $color-white;
	}

	::-webkit-scrollbar-thumb {
		background: #919CAB;
	}

	::-webkit-scrollbar-thumb:window-inactive {
		@include bg-rgba(145, 156, 171, 0.7);
	}
}

.b-fixed-scrollbar {
	display: none;
	overflow-x: scroll;
	position: fixed;
	width: 100%;
	margin-top: 0;
	z-index: 3;

	div {
		background: rgba(255, 255, 255, 0.01);
	}
}

.b-table--fixed-scrollbar {
	position: relative;
	overflow: hidden;
	@extend .b-table--scale-1;

	table {
		// margin-top: -2px;
		// margin-bottom: 2px;

		.browser-safari & {
			margin-bottom: 0;
		}
	}

	.b-scroll-arrow {
		position: absolute;
		height: calc(100% - 14px);
		width: 30px;
		top: 0;
		margin-top: 0;
		z-index: 1;
		transition: opacity 0.3s ease, transform 0.3s ease;
		cursor: pointer;
		opacity: 1;
		font-size: 16px;

		&:not(.disabled):hover {
			opacity: 0.7;
		}

		&.disabled {
			opacity: 0.4;
		}

		&.loaded {
			transform: none;
		}

		#colorbox & {
			height: calc(100% - 1.125em);
		}
	}

	.b-scroll-arrow__icon {
		position: absolute;
		transform: translateY(-50%);
		color: $color-white;
	}

	.b-scroll-arrow--left {
		background: linear-gradient(90deg, rgba(97, 110, 121, 1), rgba(255, 255, 255, 0));
		left: 0;
		transform: translateX(-100%);

		&:not(.disabled):active {
			background: linear-gradient(90deg, rgba(253, 201, 10, 1), rgba(255, 255, 255, 0));
		}

		.b-scroll-arrow__icon {
			@include webfont-icon($webfont-icon--prev);
			margin-left: 5px;
		}
	}

	.b-scroll-arrow--right {
		background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(97, 110, 121, 1));
		right: 0;
		transform: translateX(100%);

		&:not(.disabled):active {
			background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(253, 201, 10, 1));
		}

		.b-scroll-arrow__icon {
			@include webfont-icon($webfont-icon--next);
			margin-left: 12px;
		}
	}
}

@if ($responsive) {
	@media (min-width: $breakpoint-sm + 1px) {
		.b-table--no-xls .button--excel {
			display: none;
		}
	}

	@include breakpoint(sm) {
		.b-table {
			margin-bottom: 20px;
			overflow: hidden;
			position: relative;

			table {
				position: absolute;
				opacity: 0;
				visibility: hidden;
				@include font-size(20px);
			}
		}

		.figure-buttons .button--zoom,
		.b-table__toggle-visible-rows-trigger {
			display: none;
		}
	}
}
