.summary-page {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-image: url('/images/components/summary/images/bg.jpg');
	background-position: center;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;

	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&:before {
		background: $color-black;
		mix-blend-mode: multiply;
		opacity: 0.45;
		z-index: 2;
	}

	&:after {
		background: linear-gradient(312.21deg, #DB216C 22.42%, #3F88FF 71.61%);
		mix-blend-mode: color;
		opacity: 0.35;
		z-index: 1;
	}

	&__inner {
		text-align: center;
		position: relative;
		z-index: 2;
		margin-top: 55px;
	}
}

.body--page-summary {
	.content-area {
		padding: 0;
		height: 100%;
	}

	.l-layout-wrapper .l-page {
		flex-direction: row;
		padding: 0;
	}

	.l-page__content {
		padding: 0;
	}

	.l-layout-wrapper--1 .l-header {
		margin-bottom: 0;
	}
}
@include breakpoint(sm) {
	.summary-page {
		background-image: url('/images/components/summary/images/bg.png');
	}
}

@include breakpoint(xs) {
	.summary-page {
		@include rem(padding, 20px);
	}
}
