.b-pagenav--buttons {
	height: auto;
	max-width: $pagenav--link-width;

	.b-pagenav {
		&__link {
			position: relative;
			display: block;
			min-width: $pagenav--link-width;
			@include rem(height, $pagenav--link-height);
			background-color: $pagenav--btn-bg;
			border: 1px solid rgba($color-white, 0.3);
			border-radius: 30px;
			float: right;
			@include rem(margin-bottom, 23px);
			text-decoration: none;
			transition: all 0.2s ease;

			@media (min-width: $breakpoint-md) {
				@include hover {
					background: $pagenav--btn-bg-hover;
					color: $pagenav--icon-color-hover;
					border-color: #0F435D;

					.b-pagenav__title {
						margin-right: 0px;
						visibility: visible;
						opacity: 1;
						width: auto;
						max-width: 500px;
						padding-right: 35px;
						padding-left: 15px;
						text-align: right;
						@include font-size(15px);
					}

					.b-icon {
						&:after {
							opacity: 0;
							visibility: hidden;
						}
					}
				}

				&.is-touch {
					background: $pagenav--btn-bg-hover;
					color: $pagenav--icon-color-hover;
					border-color: #0F435D;

					.b-pagenav__title {
						margin-right: 0px;
						visibility: visible;
						opacity: 1;
						width: auto;
						max-width: 500px;
						padding-right: 35px;
						padding-left: 15px;
						text-align: right;
						@include font-size(15px);
					}

					.b-icon {
						&:after {
							opacity: 0;
							visibility: hidden;
						}
					}
				}
			}

			&:active,
			&.is-touch {
				color: $pagenav--icon-color-active;
				background: $pagenav--btn-bg-active;
			}
		}

		&__link--hide-fixed {
			opacity: 0;
		}

		&__link--hide {
			display: none;
		}

		&__link--disabled,
		&__scrollup--disabled {
			opacity: 0.3;
			pointer-events: none;
			background-color: $pagenav--btn-bg;
			cursor: default;
			outline: 0;

			@include hover {
				background-color: $pagenav--btn-bg;

				.b-pagenav__title {
					left: -9999px;
					opacity: 0;
				}
			}
		}

		&__scrollup {
			@extend .b-pagenav__link;
			margin-bottom: 0;
		}

		&__title {
			color: $pagenav--title-color;
			background-color: $pagenav--title-bg;
			position: relative;
			z-index: 1;
			white-space: nowrap;
			margin-right: 0px;
			display: inline-block;
			visibility: hidden;
			vertical-align: middle;
			width: auto;
			max-width: 0px;
			opacity: 0;
			text-align: center;
			padding-right: 0px;
			height: 100%;
			line-height: 45px;
			transition: all 0.3s ease;
			pointer-events: none;

			.b-pagenav-help {
				display: none;
			}
		}
	}

	.b-icon {
		position: absolute;
		padding: 15px;
		top: 50%;
		right: 0;
		transform: translate(-1.5px, -50%);
		font-size: 6px;
		line-height: 0;
		@include webfont-icon($webfont-icon--i-see-down);
		pointer-events: none;

		&:before {
			transform: rotate(-90deg);
		}

		&:after {
			content: '';
			display: block;
			border: 1px solid $pagenav--icon-color;
			width: 30px;
			height: 1px;
			position: absolute;
			top: calc(50% - 1px);
			right: 50%;
			transform: translate(-2.3px, calc(-50% + 1px));
			// transform: translate(-1.5px, calc(-50% + 0.5px));
			opacity: 1;
			visibility: visible;
			transition: opacity 0.2s ease;
		}
	}

	.b-icon--scrollup {
		display: block;
		text-align: center;
		transform: translate(-2.5px, -50%) rotate(-90deg);
	}

	.b-icon--prev {
		transform: translate(-2.5px, -50%) rotate(180deg);
	}
}

@if ($responsive) {
	@include breakpoint(lg) {
		$pagenav--link-width: 40px;
		$pagenav--link-height: 40px;

		.b-pagenav--buttons {
			height: auto;
			max-width: $pagenav--link-width;

			.b-pagenav {
				&__link {
					min-width: $pagenav--link-width;
					@include rem(height, $pagenav--link-height);
					border-radius: 20px;
					@include rem(margin-bottom, 10px);

					@include hover {
						.b-pagenav__title {
							padding-right: 30px;
						}
					}

					&:active,
					&.is-touch {
						.b-icon {
							&:after {
								border-color: $pagenav--icon-color-active;
							}
						}
					}
				}

				&__title {
					line-height: 35px;
				}
			}

			.b-icon {
				font-size: 5px;
				transform: translate((2.5px + 0.5px), -50%);

				&:after {
					width: 24px;
					transform: translate(-3px, calc(-50% + 0.5px));
				}

				&--scrollup {
					transform: translate(1.5px, -50%) rotate(-90deg);
				}

				&--prev {
					transform: translate(0.5px, -50%) rotate(180deg);
				}
			}
		}
	}

	@include breakpoint(md) {
		.b-pagenav--buttons {
			.b-pagenav__title {
				display: none;
			}
		}
	}

	@media (max-width: 1080px) {
		.b-pagenav--buttons {
			width: 100%;
			max-width: none;
			display: flex;
			justify-content: space-between;

			.b-pagenav__link {
				&--next {
					order: 1;
				}
			}

			.b-pagenav__title {
				display: none;
			}
		}
	}
}
