/* Links */

/**
 * Remove the gray background color from active links in IE 10.
 */

a {
	color: $link-default-color;
	background-color: transparent;
	transition: color 0.2s ease;

	&:visited {
		color: $link-visited-color;
	}

	@include hover {
		color: $link-hover-color;
	}

	&:active,
	&:visited:active {
		color: $link-active-color;
	}

	&:active,
	&:hover {
		outline: 0;
	}

	&.btn {
		&:visited {
			color: $btn--color;
		}

		@include hover {
			color: $btn--color-hover;
		}

		&:active,
		&:visited:active {
			color: $btn--color-active;
		}
	}

	&.link--external {
		&:before {
			transition: color 0.2s ease;
			color: $link-default-color;
		}

		&:visited {
			&:before {
				color: $link-visited-color;
			}
		}

		@include hover {
			&:before {
				color: $link-hover-color;
			}
		}

		&:active,
		&:visited:active {
			&:before {
				color: $link-active-color;
			}
		}
	}
}

[data-anchor-link] {
	cursor: pointer;
}

.link--external {
	@include webfont-icon($webfont-icon--external-link);

	&:before {
		display: inline-block;
		@include font-size(14px);
		margin-right: 0.2em;
		vertical-align: middle;
	}
}

.link--phone {
	white-space: nowrap;
}

.b-link-icon {
	display: flex;
	//flex: 0 1 auto;
	align-items: center;

	&__icon {
		margin-right: 5px;
		flex-basis: 100%;
		max-width: 25px;
		min-width: 20px;
	}

	&__text {
		flex-shrink: 1;
	}
}

//иконка слева
.b-link-icon--left {
	.b-link-icon__icon {
		img {
			display: block;
		}
	}
}

//иконка справа
.b-link-icon--right {
	.b-link-icon__icon {
		margin-left: 5px;
		margin-right: auto;

		img {
			display: block;
		}
	}
}

//размеры иконок, при необходимости - добавлять модификаторы - xs, xm, xl

//по умолчанию без модификации
.b-link-icon--size-s {
}

//средний размер
.b-link-icon--size-m {
	.b-link-icon__icon {
		flex-basis: 100%;
		max-width: 30px;
	}
}

//большой размер
.b-link-icon--size-l {
	.b-link-icon__icon {
		flex-basis: 100%;
		max-width: 40px;
	}
}

.b-link-icon--size-free {
	.b-link-icon__icon {
		flex-basis: auto;
		max-width: initial;
	}
}
