$accordion--controls-height: 40px;
$accordion--button-size: 40px;
$accordion-horizontal-padding: 49px;
$accordion--button-bg: $color-blackcurrant;
$accordion--button-bg-hover: $accordion--button-bg;
$accordion--button-bg-active: $accordion--button-bg;
$accordion--button-text-color: $base-text-color;
$accordion--button-text-color-hover: $color-white;
$accordion--button-text-color-active: $color-white;
$accordion--button-border-color: $base-text-color;
$accordion--button-border-color-active: $color-medium-violet-red;
$accordion--article-bg: $color-blackcurrant;
$accordion--control-icon-bg: $color-navy-blue;
$accordion--control-icon-color: $color-white;
$accordion--control-item-bg: $color-celeste;
$accordion--control-item-bg-hover: $color-ironside-gray;
$accordion--control-item-bg-active: $color-mine-shaft;
$accordion--control-text-color: $base-text-color;
$accordion--control-text-color-hover: $color-white;
$accordion--control-text-color-active: $color-white;
$accordion--article-close-bg: $base-text-color;
$accordion--article-close-bg-hover: $color-navy-blue;
$accordion--article-close-bg-active: $color-prussian-blue;
$accordion--button-font-size: 20px;

@if ($contrast) {
	$accordion--button-bg: $color-celeste;
	$accordion--button-bg-hover: $color-ironside-gray;
	$accordion--button-bg-active: $color-mine-shaft;
	$accordion--button-text-color: $base-text-color;
	$accordion--button-text-color-hover: $color-white;
	$accordion--button-text-color-active: $color-white;
	$accordion--article-bg: $color-blackcurrant;
	$accordion--control-icon-bg: $color-navy-blue;
	$accordion--control-icon-color: $color-white;
	$accordion--control-item-bg: $color-celeste;
	$accordion--control-item-bg-hover: $color-ironside-gray;
	$accordion--control-item-bg-active: $color-mine-shaft;
	$accordion--control-text-color: $base-text-color;
	$accordion--control-text-color-hover: $color-white;
	$accordion--control-text-color-active: $color-white;
	$accordion--article-close-bg: $base-text-color;
	$accordion--article-close-bg-hover: $color-navy-blue;
	$accordion--article-close-bg-active: $color-prussian-blue;
}

%control-icon {
	content: '';
	display: block;
	position: absolute;
	background-color: $accordion--control-icon-color;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	@include rem(width, 20px);
	@include rem(height, 2px);
}

.b-accordion {
	&__section {
		@include rem(margin-bottom, 1px);
	}

	&__header {
		position: relative;

		&:before {
			content: '';
			display: inline-block;
			@include rem(height, 24px);
			width: 1px;
			background-color: $color-white;
			position: absolute;
			z-index: 1;
			right: 0;
			margin-right: $accordion-horizontal-padding + $accordion--button-size / 2 - 1;
			// bottom: 31px;
			bottom: calc(50% + 0.5px);
			visibility: hidden;
			opacity: 0;
			transition: all 0.2s ease;
		}

		@media (min-width: $breakpoint-md) {
			&:not(.is-expand):hover {
				&:before {
					opacity: 1;
					visibility: visible;
				}

				.b-accordion__button {
					border-color: $accordion--button-border-color;

					&:before, &:visited {
						color: $accordion--button-text-color;
					}
				}
			}
		}

		&:active {
			&:hover:before {
				opacity: 0;
				visibility: hidden;
			}
		}
	}

	&__button {
		display: block;
		position: relative;
		text-decoration: none;
		color: $accordion--button-text-color;
		background-color: $accordion--button-bg;
		border-bottom: 1px solid rgba($accordion--button-border-color, 0.3);
		cursor: pointer;
		transition: all .2s ease-in-out;
		@include rem(padding, 22px $accordion-horizontal-padding);
		@include rem(padding-right, $accordion-horizontal-padding + $accordion--button-size + 10px);
		@include font-size($accordion--button-font-size);
		@include font(400, 'BanderaPro');
		@include webfont-icon($webfont-icon--i-see-down);
		line-height: 150%;

		// &:visited:hover {
		// 	color: $accordion--button-text-color;
		// }

		@include hover {
			color: $accordion--button-text-color-hover;
		}

		&.is-expand {
			background-color: $accordion--button-bg-active;
			border-color: $accordion--button-border-color-active;

			&:before {
				transform: translate(-14px, 50%) rotate(180deg);
				top: -5px;
				color: $accordion--button-border-color-active;
			}

			&:after {
				border-color: $accordion--button-border-color-active;
			}

			&:visited {
				color: $accordion--button-text-color-active;
				background-color: $accordion--button-bg-active;
			}

			@include hover {
				color: $accordion--button-text-color-hover;
			}
		}

		&:before,
		&:after {
			position: absolute;
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: all 0.2s ease;
			@include rem(right, $accordion-horizontal-padding);
		}

		&:before {
			font-size: 5px;
			transform: translate(-15px, 50%);
			width: 10px;
			height: 5px;
			color: $accordion--button-text-color;
		}

		&:after {
			content: '';
			@include rem(width, $accordion--button-size);
			@include rem(height, $accordion--button-size);
			border: 1px solid rgba($accordion--button-border-color, 0.3);
			border-radius: 50%;
		}
	}

	&__article {
		margin-top: 0;
		background-color: $accordion--article-bg;
		border-bottom: 1px solid transparent;
		display: none;
		transition: border-color 0.2s ease;
		@include rem(padding, 23px $accordion-horizontal-padding 35px);

		&.is-expand {
			display: block;
			border-color: $accordion--button-border-color-active;

			.b-accordion__article-close {
				&:before {
					transform: rotate(45deg);
				}

				&:after {
					transform: rotate(-45deg);
				}
			}
		}

		&--big {
			@include rem(padding-top, 40px);
			@include rem(padding-bottom, 46px);
		}
	}

	&__article-close {
		position: absolute;
		display: block;
		background: transparent;
		bottom: 0px;
		cursor: pointer;
		@include rem(width, 32px);
		@include rem(height, 32px);
		@include rem(right, 10px);

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			@include rem(width, 3px);
			@include rem(height, 15px);
			background-color: $accordion--button-text-color;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform 0.2s ease;
			border-radius: 5px;
		}

		&:before {
			@include rem(right, 19px);
			transform: rotate(-45deg);

		}

		&:after {
			@include rem(right, 10px);
			transform: rotate(45deg);
		}

		@include hover {
			background: transparent;

			&:before {
				background-color: $accordion--article-close-bg-hover;
			}

			&:after {
				background-color: $accordion--article-close-bg-hover;
			}
		}

		&:active {
			background: transparent;

			&:before {
				background-color: $accordion--article-close-bg-active;
			}

			&:after {
				background-color: $accordion--article-close-bg-active;
			}
		}

		&.is-hidden {
			display: none;
		}
	}

	&__control {
		@include rem(padding-top, 10px);
	}

	&__control-item {
		display: inline-block;
		background-color: $accordion--button-bg;
		color: $accordion--control-text-color;
		padding: 0;
		cursor: pointer;
		@include rem(height, $accordion--controls-height);
		@include rem(line-height, $accordion--controls-height);
		@include rem(margin-bottom, 10px);

		&:first-child {
			@include rem(margin-right, 10px);
		}

		@include hover {
			background-color: $accordion--control-item-bg-hover;
			color: $accordion--control-text-color-hover;
		}

		&:active {
			background-color: $accordion--control-item-bg-active;
			color: $accordion--control-text-color-active;
		}
	}

	&__control-icon {
		display: block;
		position: relative;
		height: 100%;
		float: left;
		background-color: $accordion--control-icon-bg;
		@include rem(width, $accordion--controls-height);
	}

	&__control-text {
		@include rem(padding-left, 15px);
		@include rem(padding-right, 15px);
	}

	&__control-item--expand {
		.b-accordion__control-icon {
			&:before,
			&:after {
				@extend %control-icon;
			}

			&:before {
				transform: rotate(0deg);
			}

			&:after {
				transform: rotate(90deg);
			}
		}
	}

	&__control-item--collapse {
		.b-accordion__control-icon:before {
			@extend %control-icon;
		}
	}
}

$accordion-fn--button-text-color: $figma--accordion--accordion_normal--color;
$accordion-fn--button-text-color-hover: $figma--accordion--accordion_hover--color;
$accordion-fn--button-text-color-active: $figma--accordion--accordion_expand--color;
$accordion-fn--button-border: $figma--accordion--accordion_normal--border;
$accordion-fn--button-border-hover: $figma--accordion--accordion_hover--border;
$accordion-fn--button-border-active: $figma--accordion--accordion_expand--border;
$accordion-fn--button-bg: $figma--accordion--accordion_normal--bg;
$accordion-fn--button-bg-hover: $figma--accordion--accordion_hover--bg;
$accordion-fn--button-bg-active: $figma--accordion--accordion_expand--bg;

.b-accordion-functional {
	&__button {
		display: block;
		position: relative;
		text-decoration: none;
		color: $accordion-fn--button-text-color;
		background-color: $accordion-fn--button-bg;
		border-bottom: 1px solid $accordion-fn--button-border;
		cursor: pointer;
		transition: all .2s ease-in-out;
		@include rem(padding, 10px 40px 10px 20px);
		@include font-size(20px);

		&:visited {
			color: $accordion-fn--button-text-color;
		}

		@include hover {
			color: $accordion-fn--button-text-color-hover;
			border-color: $accordion-fn--button-border-hover;
			background-color: $accordion-fn--button-bg-hover;

			&:before,
			&:after {
				background-color: $accordion-fn--button-text-color-hover;
			}

			&:visited {
				color: $accordion-fn--button-text-color-hover;
			}
		}

		&.is-expand,
		&:active {
			color: $accordion-fn--button-text-color-active;
			border-color: $accordion-fn--button-border-active;
			background-color: $accordion-fn--button-bg-active;
			font-weight: 700;

			&:before,
			&:after {
				background-color: $accordion-fn--button-text-color-active;
			}

			&:visited {
				color: $accordion-fn--button-text-color-active;
			}
		}

		&.is-expand {
			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			background-color: $accordion-fn--button-text-color;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform 0.2s ease, background-color .2s ease-in-out;
			border-radius: 5px;
			@include rem(width, 3px);
			@include rem(height, 15px);
		}

		&:before {
			@include rem(right, 29px);
			transform: rotate(-45deg);

		}

		&:after {
			@include rem(right, 20px);
			transform: rotate(45deg);
		}
	}

	&__article {
		position: relative;
		margin-top: 0;
		display: none;
		@include rem(padding, 15px 25px 35px 20px);

		&.is-expand {
			display: block;

			.b-accordion__article-close {
				&:before {
					transform: rotate(45deg);
				}

				&:after {
					transform: rotate(-45deg);
				}
			}
		}
	}
}

@if ($responsive) {
	@include breakpoint(lg) {
		.b-accordion {
			&__button {
				@include font-size(18px);
			}
		}
	}

	@include breakpoint(md) {
		.b-accordion {
			&__section:first-child {
				.b-accordion__button {
					padding-top: 7px;
				}
			}

			&__header::before {
				display: none;
			}
		}
	}

	@include breakpoint(xs) {
		$accordion--button-size: 36px;
		$accordion-horizontal-padding: 20px;

		.b-accordion {
			&__button {
				@include rem(padding, $accordion-horizontal-padding);
				@include rem(padding-right, $accordion-horizontal-padding + $accordion--button-size + 10px);

				&:before {
					transform: translate(-13px, 50%);
				}

				&:before,
				&:after {
					@include rem(right, $accordion-horizontal-padding);
				}

				&:after {
					@include rem(width, $accordion--button-size);
					@include rem(height, $accordion--button-size);
				}

				&.is-expand {
					&:before {
						transform: translate(-12px, 50%) rotate(180deg);
					}
				}
			}

			&__article {
				@include rem(padding, $accordion-horizontal-padding);
			}
		}
	}
}
