$bc-link-color: rgba($link-default-color, 0.8);
$bc-link-color-hover: $link-hover-color;
$bc-link-color-active: $link-active-color;
$bc-link-color-visited: $bc-link-color;
$bc-home-icon-color: rgba($color-white, 0.5);
$bc-home-icon-color-hover: $link-hover-color;
$bc-home-icon-color-active: $link-active-color;
$bc-item-color: rgba($color-white, 0.5);
$bc-dash-color: $color-ironside-gray;
$bc-sub-bg: #cdcdcd;
$bc-sub-link-color: rgba($link-default-color, 0.8);
$bc-sub-link-color-hover: $link-hover-color;

@if ($contrast) {
	$bc-link-color: rgba($link-default-color, 0.8);
	$bc-link-color-hover: $link-hover-color;
	$bc-link-color-active: $link-active-color;
	$bc-link-color-visited: $bc-link-color;
	$bc-home-icon-color: rgba($color-white, 0.5);
	$bc-home-icon-color-hover: $link-hover-color;
	$bc-home-icon-color-active: $link-active-color;
	$bc-item-color: rgba($color-white, 0.5);
	$bc-dash-color: $color-ironside-gray;
	$bc-sub-bg: #cdcdcd;
	$bc-sub-link-color: rgba($link-default-color, 0.8);
	$bc-sub-link-color-hover: $link-hover-color;
}

.breadcrumbs {
	@extend .no-markers;
	margin-top: 0;
	@include font-size(19px);
	@include rem(line-height, 34px);
	margin-bottom: 50px;

	ul.breadcrumbs-sub {
		display: none;
		position: absolute;
		white-space: nowrap;
		color: $bc-sub-link-color;
		background-color: $bc-sub-bg;
		margin-top: 0;
		z-index: 11;
		@include rem(padding, 2px 5px);

		li.breadcrumbs-sub__item {
			margin-top: 0;
			padding-left: 0;

			& + .breadcrumbs-sub__item {
				@include rem(margin-top, 4px);
			}
		}

		&__link {
			color: $bc-sub-link-color;
			text-decoration: none;

			&:visited {
				color: $bc-sub-link-color;

				&:hover {
					color: $bc-sub-link-color-hover;
				}
			}

			&:hover {
				color: $bc-sub-link-color-hover;

			}
		}
	}

	ul.breadcrumbs__list {
		margin-top: 0;
	}

	&__item {
		position: relative;
		display: inline-block;
		color: $bc-item-color;
		vertical-align: middle;
		padding-left: 0 !important;

		& + .breadcrumbs__item {
			padding-left: 33px !important;

			&:after {
				content: '';
				position: absolute;
				width: 3px;
				height: 6px;
				left: 15px;
				border: 3px solid transparent;
				border-left: 3px solid rgba($color-white, 0.5);
			}
		}

		&:hover {
			.breadcrumbs-sub {
				display: block;
			}
		}
	}

	&__link {
		color: $bc-link-color;
		text-decoration: none;

		&:visited {
			color: $bc-link-color-visited;
		}

		&:hover {
			color: $bc-link-color-hover;
		}

		&:active {
			color: $bc-link-color-active;
		}
	}

	&__link--home {
		position: relative;
		display: block;
		color: $bc-home-icon-color;
		font-size: 0;
		text-decoration: none;
		@include webfont-icon($webfont-icon--home);

		&:before {
			@include font-size(19px);
			@include rem(line-height, 34px);
			vertical-align: middle;
		}

		&:hover {
			color: $bc-home-icon-color-hover;
		}

		&:active {
			color: $bc-home-icon-color-active;
		}

		&:visited {
			color: $bc-home-icon-color;
		}
	}
}

@if ($responsive) {
	@include breakpoint(lg) {
		.breadcrumbs {
			@include font-size(12px);
			@include rem(line-height, 22px);

			&__link--home:before {
				@include font-size(16px);
				line-height: 14px;
			}
		}
	}
}
