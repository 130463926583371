$pagenav--link-width: 50px;
$pagenav--link-height: 50px;
$pagenav--icon-width: $pagenav--link-width / 1.5;
$pagenav--icon-height: $pagenav--link-width - 4;
$pagenav--icon-color: $color-white;
$pagenav--icon-color-hover: $color-deep-sky-blue;
$pagenav--icon-color-active: $color-dodger-blue;
$pagenav--btn-bg: $color-blackcurrant;
$pagenav--btn-bg-hover: linear-gradient(0deg, rgba(0, 185, 242, 0.05), rgba(0, 185, 242, 0.05)), rgba(22, 17, 30, 0.95);;
$pagenav--btn-bg-active: $pagenav--btn-bg-hover;
$pagenav--title-width: 250px;
$pagenav--title-color: $color-white;
$pagenav--title-bg: transparent;
$pagenav--title-bg-active: transparent;

@if ($contrast) {
	$pagenav--icon-color: $color-white;
	$pagenav--icon-color-hover: $color-deep-sky-blue;
	$pagenav--icon-color-active: $color-dodger-blue;
	$pagenav--btn-bg: $color-blackcurrant;
	$pagenav--btn-bg-hover: linear-gradient(0deg, rgba(0, 185, 242, 0.05), rgba(0, 185, 242, 0.05)), rgba(22, 17, 30, 0.95);;
	$pagenav--btn-bg-active: $pagenav--btn-bg-hover;
	$pagenav--title-color: $color-white;
	$pagenav--title-bg: transparent;
	$pagenav--title-bg-active: $color-dark-gray;
}
