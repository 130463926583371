blockquote {
	position: relative;
	background-color: rgba(216, 218, 217, 0.25);
	padding: 30px 16px 30px 80px;
	border-bottom: 4px solid $color-navy-blue;

	.text {
		position: relative;

		&:before {
			content: "\"";
			position: absolute;
			font-size: 4em;
			top: -0.3em;
			left: -50px;
			color: $color-navy-blue;
		}
	}

	.person {
		margin-top: 17px;

		&__name {
			display: block;
			font-size: 18px;
			color: $color-navy-blue;
			font-weight: bold;
		}

		&__position {
			display: block;
		}

		&__company {
			display: block;
		}
	}
}

.quote--photo {
	display: flex;
	padding: 0;
	background-color: transparent;
	border: none;

	.quote__photo {
		flex-shrink: 0;
		width: 30%;

		img {
			display: block;
		}
	}

	.person {
		margin-top: 17px;

			&__name {
				display: block;
				font-size: 25px;
				color: $color-white;
				font-weight: bold;
			}

			&__position {
				color: $color-white;
				display: block;
				font-size: 17px;
			}

			&__company {
				color: $color-white;
				display: block;
			}
		}

	.quote__content {
		position: relative;
		padding: 30px 16px 30px 80px;
		align-self: center;

		.text {
			position: relative;
			color: $color-medium-violet-red;
			font-size: 24px;
			p {
				line-height: 24px;
				}

			&:before {
			content: "«";
			position: absolute;
			color: $color-medium-violet-red;
		    opacity: 0.3;
		    top: -25px;
		    left: -100px;
			font-size: 200px;
			z-index: -1;
			}
		}

	}
}



@include breakpoint(sm) {
	blockquote {
		padding: 24px 16px 24px 10px;

		.text {
			&:before {
				left: -28px;
			}
		}
	}

	.quote--photo {
		display: block;
		padding: 24px 16px 24px 10px;

		.quote__photo {
			width: 55%;
			margin-bottom: 25px;
			margin-left: auto;
    		margin-right: auto;
		}

		.quote__content {
			padding: 24px 16px 24px 40px;

			.text {

				&:before {
			        top: -20px;
				    left: -60px;
				    font-size: 110px;
				}
			}
		}
	}
}

@include breakpoint(xs) {
	.quote--photo {

		.quote__photo {
			width: 95%;
		}
	}
}
