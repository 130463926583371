$copyright_base--color: $color-white;
$copyright_base--link-color: $link--fn-default-color;
$copyright_base--link-color-hover: $link--fn-hover-color;
$copyright_base--link-color-active: $link--fn-active-color;

@if ($contrast) {
	$copyright_base--color: $color-white;
	$copyright_base--link-color: $color-white;
	$copyright_base--link-color-hover: $color-white;
}

.b-copyright {
	color: $copyright_base--color;
	transition: color 0.2s ease;
	@include font(300);
	@include font-size(18px);

	@if ($responsive) {
		@include breakpoint(lg) {
			@include font-size(14px);
		}
	}

	&:hover,
	&:visited:hover {
		color: $copyright_base--link-color-hover;
	}

	&:active,
	&:visited:active {
		color: $copyright_base--link-color-active;
	}

	&__name--link {
		color: $copyright_base--link-color;
		text-decoration: none;

		&:visited {
			color: $copyright_base--link-color;
		}

		&:hover,
		&:visited:hover {
			color: $copyright_base--link-color-hover;
			text-decoration: underline;
		}

		&:active,
		&:visited:active {
			color: $copyright_base--link-color-active;
			text-decoration: none;
		}
	}
}
