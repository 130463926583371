.b-logo {
	display: block;
	@include rem(width, 294px);
	@include rem(height, 32px);

	&__image {
		width: 100%;
		height: 100%;
	}

	@if ($responsive) {
		@include breakpoint(lg) {
			@include rem(width, 176px);
			@include rem(height, 20px);
		}

		@include breakpoint(sm) {
			@include rem(width, 151px);
			@include rem(height, 17px);
		}
	}
}

.logo-svg {
	width: 100%;
	height: 100%;
	display: block;
}
