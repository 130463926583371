// paragraf
.figma-class--paragraf {
	@include font(300);
	@include font-size(16px);
	color: rgba(36, 31, 33, 1);
}
// H1
.figma-class--H1 {
	@include font(700);
	@include font-size(38px);
	color: rgba(23, 54, 66, 1);
}
// H3
.figma-class--H3 {
	@include font(500);
	@include font-size(20px);
	color: rgba(36, 31, 33, 1);
	text-transform: uppercase;
}
// H2
.figma-class--H2 {
	@include font(500);
	@include font-size(24px);
	color: rgba(207, 18, 38, 1);
	text-transform: uppercase;
}
// H4
.figma-class--H4 {
	@include font(700);
	@include font-size(18px);
	color: rgba(207, 18, 38, 1);
}
// H5
.figma-class--H5 {
	@include font(700);
	@include font-size(16px);
	color: rgba(23, 54, 66, 1);
}
// video_h
.figma-class--video_h {
	@include font(400);
	@include font-size(16px);
	color: rgba(207, 18, 38, 1);
}
// table_h
.figma-class--table_h {
	@include font(300);
	@include font-size(16px);
	color: rgba(23, 54, 66, 1);
}
// graf_h
.figma-class--graf_h {
	@include font(400);
	@include font-size(16px);
	color: rgba(23, 54, 66, 1);
}
// link
.figma-class--link {
	@include font(400);
	@include font-size(16px);
	color: rgba(255, 255, 255, 1);
}
// btn
.figma-class--btn {
	@include font(400);
	@include font-size(16px);
	color: rgba(255, 255, 255, 1);
}
// functional-heading
.figma-class--functional-heading {
	@include font(500);
	@include font-size(18px);
	color: rgba(36, 31, 33, 1);
	text-transform: uppercase;
}
// functional-title
.figma-class--functional-title {
	@include font(700);
	@include font-size(18px);
	color: rgba(36, 31, 33, 1);
}
// funcional-subtitle
.figma-class--funcional-subtitle {
	@include font(500);
	@include font-size(14px);
	color: rgba(36, 31, 33, 1);
}
