.video {
	width: 100%;
	max-width: 850px;
	margin: auto;
	position: relative;

	/*&:before {
		content: '';
		display: block;
		border: 1px solid $color-white;
		opacity: 0.3;
		transform: rotate(-45deg);
		position: absolute;
		top: 20px;
		left: -160px;
		z-index: -1;
		@include rem(width, 400px);
		@include rem(height, 400px);
	}

	&:after {
		content: '';
		display: block;
		border: 1px solid $color-white;
		opacity: 0.3;
		transform: rotate(-45deg);
		position: absolute;
		bottom: 40px;
		right: -190px;
		z-index: -1;
		@include rem(width, 400px);
		@include rem(height, 400px);
	}*/

	&__wrapper {
		position: relative;
	}

	&__button {
		width: 118px;
    height: 118px;
    display: block;
    border: 1px solid transparent;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
		@include webfont-icon($webfont-icon--i-play);

		&::before {
			display: block;
		}

		&:hover {
			color: $btn--bg;
		}

		&:active {
			transform: translate(-50%, -50%);
			background-color: $btn--bg;
			border-color: transparent;
			color: $btn--bg-active;
		}

		&--fullscreen {
			bottom: 10px;
			right: 10px;
			color: $color-white;
			background: transparent;
			border: none;
			font-size: 18px;
			top: unset;
			left: unset;
			transform: none;
			width: auto;
			height: auto;
			display: none;
			transition: color 0.2s ease;
			@include webfont-icon($webfont-icon--enlarge);

			&::after {
				display: none;
			}

			&:hover {
				color: $btn--bg-hover;
			}

			&:active {
				transform: none;
				background: transparent;
				color: $color-dodger-blue;
			}
		}
	}

	&__img {
		margin: 0!important;
		width: 100%;
		height: auto;
	}

	a {
		padding: 0!important;

		&:before{
	    	content: none!important;
	    }
	}

	@include breakpoint(lg) {
		max-width: 672px;

		&__img {
			// width: auto;
			height: 363px;
		}
	}

	@include breakpoint(md) {
		max-width: 800px;
		margin: 0 auto;

		&__button {
			width: 49px;
			height: 49px;
		}

		&__img {
			height: auto;
		}
	}
}
