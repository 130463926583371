.body--slider-single {
  .l-layout-wrapper--1 {
    .l-footer {
      display: none;
    }
  }

  .l-page__content {
    width: 100%;
  }

  .l-layout-wrapper--1 .content-area {
    padding: 0;
  }

  .l-layout-wrapper__page {
    max-width: 100%;
    width: 100%;
    flex-basis: auto;
  }

  .b-slider-wrapper--single {
    position: relative;
  }

  .l-slider-item__inner {
    margin: auto;
    margin: 0 3.5vw;
  }

  .b-slider-item {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  }

  .b-slider-item {
    &[data-hash="individual-universes"] {
      background-image: url('/images/components/slider/images/bg--preloader.jpg');
    }

    &[data-hash="world-standards"] {
      background-image: url('/images/components/slider/images/bg--0.jpg');
    }

    &[data-hash="advertising"] {
      background-image: url('/images/components/slider/images/bg--1.jpg');
    }

    &[data-hash="digital-transformation"] {
      background-image: url('/images/components/slider/images/bg--2.jpg');
    }

    &[data-hash="information-media"] {
      background-image: url('/images/components/slider/images/bg--3.jpg');
    }

    &[data-hash="sports-broadcasting"] {
      background-image: url('/images/components/slider/images/bg--4.jpg');
    }

    &[data-hash="entertainment-content"] {
      background-image: url('/images/components/slider/images/bg--5.jpg');
    }
  }

  .b-slider-item__title,
  .b-slider-item__descripion {
    opacity: 0;
    transition: all 0.5s ease;
  }

  .b-slider-item__content {
    color: $color-white;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    *:last-child {
      margin-bottom: 0;
    }
  }

  .b-slider-item__descripion {
    width: 815px;
    line-height: 160%;
    @include font-size(21px);

    &.scrolled,
    &.scrolled-30,
    &.scrolled-60 {
      opacity: 0 !important;
    }
  }

  .b-slider-item__page {
    display: none;
    background-color: $color-blackcurrant;
    position: relative;
    margin-top: 0;
    transition: margin-top 0.5s ease;

    &.active {
      display: inherit;
      z-index: 80;
    }
  }

  .l-slider-button-wrapper {
    position: fixed;
    width: 100%;
    height: 0;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    margin: auto;
    transform: translateY(0);
    transition: transform 0.5s ease;

    &.scrolled-30 {
      transform: translateY(-93.3px);
    }

    &.scrolled-60 {
      transform: translateY(calc(var(--vh) * -0.26));
    }
  }

  .b-slider-button {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    background-color: $slider-nav--bg;
    color: $slider-nav--color;
    border: 1px solid rgba($slider-nav--border, 0.3);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    transition: color 0.2s ease, opacity 0.2s ease, border-color 0.2s ease;

    &:before {
      content: '';
      display: inline-block;
      border: 6px solid transparent;
			transition: border-color 0.2s ease, transform 0.2s ease;
    }

    &.swiper-button-disabled {
      opacity: 0.3;
    }

    &:not(.swiper-button-disabled) {
      cursor: pointer;

      @media (min-width: 799px) {
        @include hover {
          background-color: $slider-nav--bg-hover;
          border-color: $slider-nav--border-hover;
          color: $slider-nav--color-hover;
        }
      }

      &:active {
        background-color: $slider-nav--bg-active;
        border-color: $slider-nav--border-active;
				color: $slider-nav--color-active;
      }
    }

    &__text {
      text-transform: uppercase;
      position: absolute;
      letter-spacing: 0.05em;
      top: 50%;
      transform: translateY(-50%);
      @include font-size(18px);
      line-height: 25px;
    }
  }

  .b-slider-button--prev {
    left: 0;
    margin-left: 4.5vw;

    &::before {
      border-right: 8px solid $slider-nav--border;
			transform: translateX(-5px);
		}

		&:not(.swiper-button-disabled) {
      @media (min-width: 799px) {
        @include hover {
          &::before {
            border-right-color: $slider-nav--border-hover;
            transform: translateX(-10px);
          }
        }
      }

			&:active::before {
				border-right-color: $slider-nav--border-active;
			}
		}

    .b-slider-button__text {
      text-align: left;
      left: 100%;
      margin-left: -10px;
    }
  }

  .b-slider-button--next {
    right: 0;
    margin-right: 4.5vw;

    &::before {
      border-left: 8px solid $slider-nav--border;
      transform: translateX(5px);
		}

		&:not(.swiper-button-disabled) {
      @media (min-width: 799px) {
        @include hover {
          &::before {
            border-left-color: $slider-nav--border-hover;
            transform: translateX(10px);
          }
        }
      }

			&:active::before {
				border-left-color: $slider-nav--border-active;
			}
		}

    .b-slider-button__text {
      text-align: right;
      right: 100%;
      margin-right: -10px;
    }
  }

  .l-slider-pagination-wrapper {
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 0;
    max-width: $site-width;
    margin: auto;
  }

  .b-slider-pagination {
    width: 815px;
    position: relative;
    margin: auto;
    opacity: 0;
    transition: margin 0.2s ease, opacity 0.2s ease;

    &.scrolled,
    &.scrolled-30,
    &.scrolled-60 {
      opacity: 0 !important;
    }

    &__item {
      @include rem(line-height, 22px);
      display: inline-block;
      width: auto;
      min-width: 12px;
      height: 100%;
      color: $slider-pagination--color;
      text-align: center;
      opacity: 1;
      border-radius: 100%;
      transition: color 0.2s ease, background-color 0.2s ease;

      @include hover {
        color: $slider-pagination--color-hover;
        background-color: transparent;
      }

      &:active,
      &.swiper-pagination-bullet-active {
        color: $slider-pagination--color-active;
        background-color: transparent;
      }

      &:first-child {
        vertical-align: super;
        margin-right: 3px;
      }

      &:last-child {
        vertical-align: sub;
      }
    }

    &__digits {
      width: auto;
      height: 32px;
      position: relative;
      @include font-size(20px);
      line-height: 27px;
      flex-shrink: 0;
    }

    &__line {
      @include rem(width, 197px);
			position: absolute;
      left: 30px;
      height: 1px;
			background-color: rgba($color-white, 0.3);
			top: 50%;
      transform: translateY(-50%);
      margin-left: 30px;

			&::before {
				content: '';
				position: absolute;
        left: 0;
        width: 100%;
				height: 100%;
        background-color: $color-white;
        transition: none;
      }

      &.start {
        &:before {
          transition: all 15s linear;
          animation: progress 15s linear;
        }
      }

      @keyframes progress {
        from {
          width: 0;
        }

        to {
          width: 100%;
        }
      }
		}
  }

  .b-slider-item__edges {
    $border-container-width: 100px;

    position: absolute;
    top: 0;
    height: 100%;
    z-index: 100;
    width: $border-container-width;
    left: - $border-container-width / 2;
    right: 100%;
    display: flex;
    flex-direction: column;

    &:last-child {
      left: unset;
      right: 0;
      transform: scale(-1, 1);

      span {
        &:nth-child(2) {
          left: -50%;
        }
      }
    }

    span {
      position: relative;
      display: block;
      max-width: $border-container-width / 2;
      width: 0;
      height: calc(100% / 3);
      background-color: $color-white;
      width: 0;
      transition: all 0.5s linear;

      &:nth-child(2) {
        left: 50%;
      }
    }

    &.animate {
      span {
        transition: all 0.7s linear;
        animation: slideChange 0.7s linear;

        &:nth-child(2) {
          animation-duration: 0.9s;
        }

        &:last-child {
          animation-duration: 1s;
        }
      }
    }

    @keyframes slideChange {
      0% {
        width: 0;
      }

      50% {
        width: 100%;
      }

      to {
        width: 0;
      }
    }
  }

  @media (max-width: $site-width + $grid-gutter-width * 2) {
    .b-slider-pagination {
      padding-right: $grid-gutter-width;
    }
  }

  .b-slider-wrapper {
    &__subtitle {
      margin-bottom: 20px;
      line-height: 120%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      @include font(600);
      @include font-size(18px);
      transition: all 0.5s ease;

      &.scrolled-30 {
        transform: scale(0.6) translateY(-46.6px) !important;
      }

      &.scrolled-60 {
        opacity: 0;
        transform: scale(0.6) translateY(calc(var(--vh) * -0.4)) !important;
      }
    }

    &__title {
      line-height: 1;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      @include font(300, 'BanderaPro');
      @include font-size(74px);
      @include rem(margin-bottom, 49px);
      transition: all 0.5s ease;

      &--main {
        opacity: 1;
        visibility: visible;
        @include font-size(84px);
        margin-bottom: 0;
      }

      &:not(.b-slider-wrapper__title--main):before {
        content: '#';
        display: inline-block;
        @include font(300, 'BanderaPro');
        @include font-size(80px);
        line-height: 90%;
        letter-spacing: 0.1em;
        position: absolute;
        left: -53px;
        top: 5px;
      }

      &.scrolled-30 {
        transform: scale(0.6) translateY(50px) !important;

        &.b-slider-wrapper__title--main {
          transform: scale(0.6) translateY(-143.3px) !important;
        }
      }

      &.scrolled-60 {
        transform: scale(0.6) translateY(calc(var(--vh) * -0.25)) !important;

        &.b-slider-wrapper__title--main {
          transform: scale(0.6) translateY(calc(var(--vh) * -0.5)) !important;
        }
      }
    }
  }
}

@if ($responsive) {
  @include breakpoint(lg) {
    .body--slider-single {
      .b-slider-pagination {
        width: 537px;

        &__digits {
          @include font-size(16px);
          line-height: 22px;
        }

        &__line {
          @include rem(width, 149px);
          margin-left: 13px;
        }
      }

      .b-slider-item__descripion {
        width: 545px;
        @include font-size(15px);
      }

      .b-slider-button {
        width: 56px;
        height: 56px;

        &:before {
          border: 6px solid transparent;
        }

        &__text {
          @include font-size(14px);
          line-height: 19px;
        }
      }

      .b-slider-button--prev {
        &::before {
          border-right: 8px solid $slider-nav--border;
        }
      }

      .b-slider-button--next {
        &::before {
          border-left: 8px solid $slider-nav--border;
        }
      }

      .l-slider-pagination-wrapper {
        top: 69px;
      }

      .l-slider-button-wrapper {
        &.scrolled-30 {
          transform: translateY(-70px);
        }

        &.scrolled-60 {
          transform: translateY(calc(var(--vh) * -0.31));
        }
      }

      .b-slider-wrapper {
        &__title {
          @include font-size(54px);
          @include rem(margin-bottom, 35px);

          &:not(.b-slider-wrapper__title--main):before {
            @include font-size(60px);
            left: -40px;
          }

          &--main {
            @include font-size(64px);
          }

          &.scrolled-30 {
            transform: scale(0.7) translateY(30px) !important;

            &.b-slider-wrapper__title--main {
              transform: scale(0.7) translateY(-126.6px) !important;
            }
          }

          &.scrolled-60 {
            transform: scale(0.65) translateY(calc(var(--vh) * -0.3)) !important;

            &.b-slider-wrapper__title--main {
              transform: scale(0.65) translateY(calc(var(--vh) * -0.5)) !important;
            }
          }
        }

        &__subtitle {
          &.scrolled-30 {
            transform: scale(0.7) translateY(-65px) !important;
          }

          &.scrolled-60 {
            transform: scale(0.65) translateY(calc(var(--vh) * -0.43)) !important;
          }
        }
      }
    }
  }

  @include breakpoint(md) {
    .body--slider-single {
      .b-slider-item__descripion {
        font-size: 12px;
        line-height: 140%;

        &.scrolled-30 {
          transform: translateX(50px) translateY(-53.3px) !important;
          opacity: 1 !important;
        }

        &.scrolled-60 {
          transform: translateX(50px) translateY(-53.3px) !important;
          opacity: 0 !important;
        }
      }

      .b-slider-button__text {
        opacity: 0;
        visibility: hidden;
        font-size: 9px;
        line-height: 12px;
        transition: all 0.2s ease;
      }

      .l-slider-button-wrapper {
        &.scrolled-60 {
          .b-slider-button__text {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .b-slider-wrapper {
        &__subtitle {
          @include font-size(11px);
          margin-bottom: 8px;

          &.scrolled-30 {
            transform: translateY(-73.33px) !important;
          }

          &.scrolled-60 {
            transform: translateY(calc(var(--vh) * -0.33)) !important;
          }
        }

        &__title {
          @include font-size(25px);
          margin-bottom: 12px;

          &:not(.b-slider-wrapper__title--main):before {
            @include font-size(25px);
            left: -15px;
            top: 2px;
          }

          &.scrolled-30 {
            transform: translateX(50px) translateY(-53.3px) !important;

            &.b-slider-wrapper__title--main {
              transform: translateY(-73.33px) !important;
            }
          }

          &.scrolled-60 {
            transform: translateX(150px) translateY(calc(var(--vh) * -0.25)) !important;

            &.b-slider-wrapper__title--main {
              transform: translateY(calc(var(--vh) * -0.33)) !important;
            }
          }
        }
      }
    }

    @media (min-height: 980px) {
      .body--slider-single {
        .l-slider-button-wrapper {
          &.scrolled-60 {
            &.scrolled-30 {
              transform: translateY(-70px);
            }

            &.scrolled-60 {
              transform: translateY(-380px);
            }
          }
        }

        .b-slider-wrapper {
          &__title {
            &.scrolled-60 {
              transform: translateX(150px) translateY(-336.6px) !important;

              &.b-slider-wrapper__title--main {
                transform: translateY(-386.6px) !important;
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint(sm) {
    .body--slider-single {
      .l-slider-item__inner {
        margin: 0 20px;
      }

      .l-slider-controls {
        width: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        position: static;
        margin-top: calc(var(--vh) - 122px);
      }

      .l-slider-pagination-wrapper {
        left: 50%;
        top: unset;
        transform: translate(-50%, 50%);
        width: auto;
        height: auto;
      }

      .l-slider-button-wrapper {
        display: flex;
        justify-content: space-between;
        position: fixed;
        padding: 0px 20px;
        bottom: unset;
        top: unset;

        &.scrolled-30 {
          transform: translateY(-150px);
        }

        &.scrolled {
          .b-slider-button__text {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .b-slider-item__descripion {
        width: auto;

        &.scrolled-30 {
          opacity: 1 !important;
          transform: translateX(0px) !important;
        }

        &.scrolled-60 {
          transform: translateX(0px) !important;
          opacity: 0 !important;
        }
      }

      .b-slider-button {
        width: 36px;
        height: 36px;
        position: relative;

        &:before {
          content: '';
          border: 4px solid transparent;
          top: 50%;
        }
      }

      .b-slider-button--prev {
        margin-left: 0;

        &::before {
          border-right: 4px solid $slider-nav--border;
          transform: translate(-3px, calc(-50% + 3px));
        }
      }

      .b-slider-button--next {
        margin-right: 0;

        &::before {
          border-left: 4px solid $slider-nav--border;
          transform: translate(3px, calc(-50% + 3px));
        }
      }

      .b-slider-pagination {
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: auto;
        width: auto;
        bottom: 15px;

        &__digits {
          font-size: 12px;
        }

        &__item {
          font-size: unset;
        }

        &__line {
          position: relative;
          left: 0;
        }
      }

      .b-slider-wrapper {
        &__title {
          @include font-size(23px);

          &--main {
            @include font-size(25px);
          }

          &:before {
            @include font-size(25px);
            left: -15px;
            top: 2px;
          }
        }
      }
    }
  }
}
