.inset {
	position: relative;
	color: $color-white;
	font-size: 14px;
	font-weight: 400;
	border: 1px solid rgba($color-white, 0.3);
	@include rem(margin-left, 85px);
	width: 100%;
	max-width: 589px;

	& + & {
		@include rem(margin-top, 66px);
	}

	.body--main-page & {
		@include rem(padding, 40px);
	}
}

.inset-main {
	position: relative;
	color: $color-white;
	font-size: 14px;
	font-weight: 400;
	border: 1px solid rgba($color-white, 0.3);
	@include rem(margin-left, 85px);
	width: 589px;

	@media (max-width: 1440px) {
		width: 372px;
	}
	@media (max-width: 1200px) {
		width: 100%;
		margin-left: 0;
	}

	& + & {
		@include rem(margin-top, 66px);
	}
}

.inset--with-figure {
	position: relative;
	font-size: 1em;
	padding-left: 75px;
	margin-bottom: 15px;
	display: block;
	font-weight: normal;

	p {
		margin-top: 15px;
		color: $color-deep-sky-blue;
	}

	strong {
		color: $color-deep-sky-blue;
		font-weight: bold;
	}

	&:before {
		content: '';
		display: block;
		border: 1px solid $color-white;
		opacity: 0.3;
		transform: rotate(-45deg);
		position: absolute;
		top: 25px;
		left: 25px;
		z-index: -1;
		@include rem(width, 120px);
		@include rem(height, 120px);
	}
}

figure.inset--with-figure {
	min-height: 170px;
}

.inset--with-title {
	@include rem(padding, 40px);
	color: $color-white;

	figcaption {
		@include font(400, 'BanderaPro');
		line-height: 110%;
		font-size: 22px;
		font-weight: 400;
		color: $color-pacific-blue;
		@include rem(margin-bottom, 18px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-transform: uppercase;

		a {
			border: 1px solid $color-pacific-blue;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			flex-shrink: 0;
			color: $color-pacific-blue;
			transition: color 0.2s ease, border-color 0.2s ease;
			position: relative;
			margin-left: 10px;

			&::before {
				content: '';
				display: inline-block;
				border: 6px solid transparent;
				border-left: 6px solid $color-pacific-blue;
				width: 6px;
				height: 12px;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(calc(-50% + 4px), -50%);
				transition: border-color 0.2s ease;
			}

			&:after {
				content: '';
				display: block;
				width: 30px;
				height: 1px;
				border: 1px solid $color-pacific-blue;
				opacity: 1;
				visibility: visible;
				position: absolute;
				top: 50%;
				left: calc(50% - 2px);
				transform: translate(-100%, -50%);
				transition: opacity 0.2s ease, border-color 0.2s ease;
			}

			@media (min-width: 799px) {
				&:hover {
					border-color: $color-white;
					color: $color-white;

					&:before {
						border-left-color: $color-white;
					}

					&:after {
						opacity: 0;
						visibility: hidden;
					}
				}
			}

			&:active {
				border-color: $color-dodger-blue;
				color: $color-dodger-blue;

				&:before {
					border-left-color: $color-dodger-blue;
				}

				&:after {
					border-color: $color-dodger-blue;
				}
			}
		}
	}

	& > *:last-child {
		margin-bottom: 0;
	}
}

.inset--content {
	@include rem(padding, 50px 50px 62px);

	.inset__content {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: -56px;

		.graphic {
			max-width: 490px;
		}
	}
}

.inset__content {
	p {
		@include font-size(19px);
		font-weight: 300;
		line-height: 130%;
	}
}

.inset__item {
	width: calc(50% - 5px);
	@include rem(margin-bottom, 56px);

	&--center {
		margin: auto;
		width: auto;
	}
}

.inset__img {
	width: 100%;
}

.inset-image {
	position: relative;

	&__text {
		position: absolute;
		padding: 7px 7px;
		background-color: $color-mountain-mist;
		opacity: 0.7;
		color: $color-black;
		top: 0;
	}
}

.inset__kit {
	display: flex;
	justify-content: space-between;
}

.inset__kit > img {
	max-width: 50% !important;
	order: 1;
}

.inset__kit--text {
	width: 50%;
	background-color: #5CADD4;
	padding: 30px 16px 30px 30px;
	font-size: 1.2rem;
	line-height: normal;
	font-weight: 700;
	font-family: 'BanderaPro';
	order: 2;
}

.inset__kit--padding {
	padding-right: 0;
	padding-left: 20px;
	margin-bottom: 20px;
}

.inset__kit--block {
	display: none;
}

@if ($responsive) {
	@include breakpoint(lg) {
		.inset {
			@include rem(margin-left, 50px);
			max-width: 372px;

			& + & {
				@include rem(margin-top, 50px);
			}

			.body--main-page & {
				@include rem(padding, 30px);
			}
		}

		.inset--content {
			@include rem(padding, 38px 26px);

			.inset__content {
				margin-bottom: -28px;

				p {
					font-size: inherit;
				}
			}
		}

		.inset__item {
			width: calc(50% - 12.5px);
			@include rem(margin-bottom, 28px);

			&--center {
				margin: auto;
				width: auto;
			}
		}

		.inset--with-title {
			@include rem(padding, 25px);

			figcaption {
				font-size: 18px;

				a {
					width: 40px;
					height: 40px;

					&::before {
						width: 5px;
						height: 10px;
						left: 11px;
						top: 50%;
						transform: translate(50%, -50%);
					}

					&:after {
						width: 24px;
					}
				}
			}

			.body--main-page & {
				@include rem(padding, 30px);
			}
		}

		.inset__content {
			p {
				@include font-size(14px);
			}
		}
	}

	@include breakpoint(md) {
		.inset {
			max-width: unset;
			margin-left: 0;

			& + & {
				@include rem(margin-top, 20px);
			}

			.body--main-page & {
				@include rem(padding, 30px);
			}
		}

		.inset--content {
			@include rem(padding, 30px);
		}

		.inset--with-title {
			@include rem(padding, 15px 20px 25px);

			figcaption {
				font-size: 14px;

				a {
					width: 36px;
					height: 36px;

					&::before {
						width: 4.5px;
						height: 9px;
						left: calc(50% - 8px);
						top: 50%;
						transform: translate(50%, -50%);
					}

					&:after {
						width: 21px;
					}
				}
			}

			.body--main-page & {
				@include rem(padding, 30px);
			}
		}
	}

	@include breakpoint(sm) {
		.inset__item {
			@include rem(margin-bottom, 24px);

			&--center {
				margin: auto;
			}
		}

		.inset--content {
			.graphic {
				max-width: 440px;
			}
		}
	}

	@include breakpoint(xs) {
		.inset--content {
			.inset__content {
				flex-direction: column;
			}
		}

		.inset__kit {
			display: block;
		}

		.inset__kit--text {
			width: 100%;
			padding: 20px 8px 20px 20px;
			font-size: 1.2rem;
		}

		.inset__kit--flex {
			display: none;
		}

		.inset__kit--block {
			display: block;
		}

		.inset__kit--padding {
			padding: 0px 20px 0 20px;
			margin-bottom: 0;
			display: block;
		}

		.inset__kit > img {
			max-width: 100% !important;
		}

		.inset__item {
			width: 100%;
		}
	}
}
