$my-report_base--item-height: $input-height;
$my-report_base--item-bg: transparent;
$my-report_base--item-move-bg: $color-gray;
$my-report_base--border-color: $color-gray;
$my-report_base--checkbox-icon: $color-celeste;
$my-report_base--checkbox-bg: $color-light-gray;
$my-report_base--checkbox-bg-checked: $color-white;
$my-report_base--checkbox-bg-hover: $color-dark-gray;
$my-report_base--link-color: $link--fn-default-color;
$my-report_base--select-all: $base-text-color;

@if ($contrast) {
	$my-report_base--item-bg: #ededed;
	$my-report_base--item-move-bg: $color-dark-gray;
	$my-report_base--checkbox-icon: #656565;
	$my-report_base--checkbox-bg: #fff;
	$my-report_base--checkbox-bg-checked: #fff;
	$my-report_base--checkbox-bg-hover: #cdcdcd;
	$my-report_base--link-color: #343434;
	$my-report_base--select-all: #343434;
}

.l-my-report {
	&__mockcup {}

	&__content {
		.b-my-report {
			display: none;
		}
	}
}

.b-my-report-mockup {
	width: 100%;

	&__inner {
		display: inline-flex;
		max-width: 450px;
		align-items: center;
	}

	&__icon {
		@include rem(min-width, 70px);
		@include rem(margin-right, 10px);
		margin-left: 1px;

		& > .webfont-icon {
			@include rem(font-size, 70px);
		}
	}

	&__link {
		&:visited {
			color: $link-default-color;
		}
	}

	&__content {}
	&__message {}
}

.b-my-report {
	&__inner {}

	&__rows {}

	&__row {
		line-height: 1.3;
		text-align: left;
		@include rem(padding, 15px);
		@include rem(font-size, 18px);
		background-color: $my-report_base--item-bg;
		border-bottom: 1px solid #fff;
		display: flex;
		align-items: center;
		position: relative;
		overflow: hidden;

		&:first-child {
			border-bottom: 2px solid $my-report_base--border-color;
			width: 100%;
			font-weight: bold;
		}
	}

	&__row-action + &__row-content {
		@include rem(padding-left, 15px);
	}

	&__row-content + &__row-action {
		@include rem(padding-left, 15px);
	}

	&__btn-group {
		@include rem(margin-top, 15px);
		display: inline-flex;
		flex-wrap: wrap;
	}

	&__btn-wrapper {
		@include rem(margin-right, 15px);
	}

	&__btn {
		width: 9em;
	}

	&__link {
		color: $link--fn-default-color;

		&:visited {
			color: $link--fn-default-color;
		}

		@include hover {
			color: $link--fn-hover-color;
			text-decoration: none;
		}

		&:active,
		&:visited:active {
			color: $link--fn-active-color;
		}
	}
}

.sortable-ghost {
	background-color: $my-report_base--item-move-bg;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}

@if ($responsive) {
	@include breakpoint(sm) {
		.b-my-report {
			&__btn-group {
				width: 100%;
			}

			&__btn-wrapper {
				//flex-basis: 50%;
				//margin-right: 0;
				@include rem(margin-bottom, 15px);
			}
		}
	}

	@include breakpoint(xs) {
		.b-my-report {
			&__btn-wrapper {
				//flex-basis: 100%;
			}
		}
	}
}
