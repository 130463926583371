.lead {
	color: #009fe3;
	@include rem(margin-bottom, 34px);

	p {
		@include font(400, 'BanderaPro');
		@include font-size(26px);
		@include rem(line-height, 45px);
		margin-bottom: 0;
	}

	&--mt {
		@include rem(margin-top, 51px);
	}

	ul > li {
		&:before {
			top: 0.4em !important;
		}
	}
}

@if ($responsive) {
	@include breakpoint(lg) {
		.lead p {
			@include font-size(20px);
			@include rem(line-height, 30px);
		}
	}

	@include breakpoint(sm) {
		.lead {

			p {
				@include font-size(18px);
				@include rem(line-height, 23px);
			}

			&--mt {
				@include rem(margin-top, $grid-gutter-width);
			}
		}
	}
}
