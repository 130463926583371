.b-buttons-panel--in-column {
	position: relative;

	.b-buttons-panel__trigger {
		display: inline-block;
		background-color: transparent;
		color: $buttons-panel--trigger-color;
		font-size: 0;
		padding: 0;
		position: relative;
		z-index: 11;
		vertical-align: bottom;
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		&:before {
			display: inline-block;
			vertical-align: middle;
			@include font-size($buttons-panel--trigger-icon-size);
		}

		&:hover {
			color: $buttons-panel--trigger-color-hover;
			background-color: transparent;
		}

		&:active {
			color: $buttons-panel--trigger-color-active;
			background-color: transparent;
		}

		&[disabled] {
			color: $buttons-panel--trigger-color;
		}
	}

	.b-buttons-panel__trigger--tools {
		@include webfont-icon($webfont-icon--tools);

		&.is-expand {
			@include webfont-icon($webfont-icon--close_2, 'after');

			&::after {
				line-height: 14px;
				@include rem(width, 16px);
				@include rem(height, 14px);
				@include font-size(14px);
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}

			&::before {
				visibility: hidden;
			}
		}
	}

	.b-buttons-panel__list {
		position: absolute;
		z-index: 10;
		top: -13px;
		right: -18px;
		display: none;
		background: $buttons-panel--list-bg-color;
		border: 1px solid rgba(255, 255, 255, 0.3);
		min-width: 216px;
		@include rem(padding, 0 20px 20px 26px);
	}

	.b-buttons-panel__item {
		margin: 0;
		padding: 0;

		&:before {
			content: none;
		}
	}

	.b-buttons-panel__link {
		white-space: nowrap;
		font-weight: 300;
		@include font-size(20px);
		@include rem(padding, 5px 0);
		@include rem(padding-left, 40px);

		&:before {
			left: 0;
			transform: translateY(-50%);
		}
	}

	.b-buttons-panel__sublist--share,
	.b-buttons-panel__sublist--social {
		@extend %clearfix;
		@include rem(padding, 0 40px);
		@include rem(padding-right, 21px);
		@include rem(margin-top, 10px);
	}

	.b-buttons-panel__sublist-link--compare {
		text-align: center;
	}

	.b-buttons-panel__sublist:not([data-sublist-slide-type="bottom"]) {
		position: absolute;
		padding: 0;
		top: 0;

		@for $i from 1 through 15 {
			&[data-length="#{$i}"] {
				width: $buttons-panel--sublist-link-width * $i;
			}
		}

		&[data-sublist-slide-type="left"] {
			right: 100%;
		}

		&[data-sublist-slide-type="right"] {
			left: 100%;
		}
	}

	.b-buttons-panel__title {
		text-transform: uppercase;
		line-height: 19px;
		letter-spacing: 0.05em;
		color: $color-white;
		opacity: 0.4;
		@include font-size(18px);
		@include rem(padding, 18px 0 9px);
	}
}

.b-buttons-panel--in-column.b-buttons-panel--static {
	background: $buttons-panel--list-bg-color;
	@include rem(padding-top, $grid-gutter-width);
	@include rem(padding-bottom, $grid-gutter-width);

	.b-buttons-panel__title {
		display: block;
		@include rem(padding-left, $grid-gutter-width);
		@include rem(padding-bottom, $grid-gutter-width);
		@include font-size($buttons-panel--title-font-size);
		color: $buttons-panel--title-color;
	}

	.b-buttons-panel__link {
		white-space: normal;
	}

	.b-buttons-panel__list {
		opacity: 1;
		display: block;
		transform: translateY(0px);
	}
}

// Панель с текстом в кнопке тригера
.b-buttons-panel--in-column.b-buttons-panel--button-with-text:not(.b-buttons-panel--download-pdf) {
	@include rem(max-width, 250px);

	.b-buttons-panel__trigger-controller {
		position: relative;
		width: 20px;
		height: 20px;
		margin-left: 14px;

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 2px;
			height: 14px;
			background-color: currentColor;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform .2s ease;
			border-radius: 5px;
		}

		&:before {
			right: 13px;
			transform: rotate(-44deg);
		}

		&:after {
			right: 4px;
			transform: rotate(44deg);
		}
	}

	.b-buttons-panel__trigger {
		display: flex;
		align-items: center;
		width: 100%;
		@include font-size($buttons-panel--trigger-text-font-size);

		&:before {
			margin-right: 9px;
		}

		&.is-expand .b-buttons-panel__trigger-controller {
			&:before {
				transform: rotate(44deg);
			}

			&:after {
				transform: rotate(-44deg);
			}
		}
	}

	.b-buttons-panel__link {
		white-space: normal;
	}
}

// Панель скачать пдф
.b-buttons-panel--in-column.b-buttons-panel--download-pdf {
	@include rem(max-width, 160px);

	.b-buttons-panel__trigger {
		@include webfont-icon($webfont-icon--pdf);
	}

	.b-buttons-panel__trigger--with-text {
		display: flex;
		align-items: center;
		@include font-size($buttons-panel--trigger-text-font-size);

		&:before {
			margin-right: 9px;
		}

		.b-buttons-panel__trigger-controller {
			display: none;
		}
	}

	.b-buttons-panel__link {
		white-space: normal;
		@include rem(padding-left, 50px);
	}

	.b-buttons-panel__link--pdf-spreads {
		@include webfont-icon($webfont-icon--pdf-double-page);
	}

	.b-buttons-panel__link--pdf-pages {
		@include webfont-icon($webfont-icon--pdf-page);
	}
}

@include breakpoint(lg) {
	.b-buttons-panel--in-column {
		.b-buttons-panel__list {
			@include rem(padding, 0 18px 12px 25px);
		}

		.b-buttons-panel__link {
			@include font-size(15px);
			@include rem(padding, 7px 0);
			@include rem(padding-left, 40px);
		}

		.b-buttons-panel__title {
			@include font-size(14px);
			@include rem(padding, 13px 0);
		}

		.b-buttons-panel__sublist--share,
		.b-buttons-panel__sublist--social {
			@include rem(padding-right, 45px);
		}
	}
}

@include breakpoint(sm) {
	.b-buttons-panel--in-column {
		.b-buttons-panel__list {
			padding-bottom: 17px;
		}

		.b-buttons-panel__trigger:hover,
		.b-buttons-panel__trigger:active {
			color: currentColor;
		}
	}
}

@include breakpoint(xs) {
	.b-buttons-panel--in-column {
		.b-buttons-panel__sublist--share {
			width: auto;
			height: auto;
		}

		.b-buttons-panel__sublist-link {
			float: none;
		}
	}
}
