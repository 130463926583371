$extendable-input-width: 500px;
$extendable-input-border-color: #cccccc;
$extendable--button-color-hover: $color-deep-sky-blue;
$extendable--button-color-active: $color-dodger-blue;

.b-search {
	&.b-search--extendable {
		width: auto;

		.b-search__wrapper-input {
			@extend %d-none;
			@include rem(width, $extendable-input-width);
			z-index: 3;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100vh;
			background: $color-radial;
		}

		.b-search__label {
			position: absolute;
			margin: auto;
			top: 50%;
			transform: translateY(-50%);
			bottom: 0;
			@include rem(left, 72px);
			@include font-size(34px);
			opacity: .5;
			cursor: text;
		}

		.b-search__input {
			width: 100%;
			height: 100%;
			@include rem(padding, 0 45px 10px 68px);
			@include font-size(16px);
			margin-top: 0;
			border-bottom: 1px solid $color-white;
			opacity: 0.8;
			color: $base-text-color;
			-webkit-appearance: none;
			border-radius: 0;
			background: none;

			&:focus {
				opacity: 1;
				border-color: $color-deep-sky-blue;
			}
		}

		.b-search__content {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			max-width: 1326px;
			margin: auto;
		}

		.b-search__input-container {
			height: auto;
			background-color: transparent;

			display: flex;
			align-items: center;
			height: 74px;
		}

		.b-search__button {
			@include webfont-icon($webfont-icon--search);
			@extend %webfont-button-animation;

			&--in {
				position: absolute;
				height: 100%;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				height: auto;

				&:before {
					font-size: 42px;
				}
			}
		}

		.b-search__result {
			@include rem(width, $extendable-input-width);
		}

		.b-search__message--bottom {
			display: none;

			&:before {
				display: none;
			}
		}

		.b-search__close {
			@include webfont-icon($webfont-icon--close_2);
			@extend %webfont-button-animation;

			&:before {
				display: block;
				@include font-size(22px);
			}

		}

		.b-search__button,
		.b-search__close {
			padding: 0;
			background-color: transparent;

			&:active {
				opacity: 0.35;
			}
		}

		&.is-error {
			.b-search__message--bottom {
				position: relative;
				display: block;
				top: 50%;

				&:before {
					display: block;
				}
			}
		}

		.js-search-button-close {
			display: block;
			position: absolute;
			right: 0;
			margin-right: 18px;
			padding: 0;

			&:before {
				font-size: 28px;
			}
		}
	}

	input::-ms-clear {
		width: 0;
		height: 0;
		display: none;
	}
}

@if ($responsive) {
	@include breakpoint(lg) {
		.b-search {
			&.b-search--extendable {
				.b-search__label {
					top: 0;
					transform: none;
					@include font-size(16px);
				}

				.b-search__content {
					margin: 0 100px;
				}

				.b-search__input-container {
					height: auto;
				}

				.js-search-button-close {
					&:before {
						font-size: 16px;
					}
				}

				.b-search__button--in {
					left: 27px;

					&:before {
						font-size: 23px;
					}
				}
			}
		}
	}

	@include breakpoint(sm) {
		.b-search {
			&.b-search--extendable {
				.b-search__content {
					margin: 0 30px;
				}
			}
		}
	}
}
