.graphic {
	&__img {
		@extend %collapse--top;
	}

	@if ($responsive) {
		@include breakpoint(sm) {
			margin-bottom: 30px;
		}
	}

	figcaption {
		@include font(500, 'BanderaPro');
		@include font-size(22px);
		@include rem(line-height, 24px);
		@include rem(margin-bottom, 35px);
		letter-spacing: 0.05em;
		color: $base-text-color;

		@if ($responsive) {
			@include breakpoint(lg) {
				@include font-size(20px);
			}

			@include breakpoint(sm) {
				white-space: unset;
			}
		}
	}

	.caption__units {
		font-weight: 300;
	}
}


.graphic__tools {
	@extend %clearfix;
	@extend %collapse--top;
	position: relative;
	border-top: 1px solid;

	.button + .button {
		margin-right: 1px;
	}
}
